import { gql } from '../__generated__/index.js';

//language=graphql
export const saveEditWish = gql(`
    mutation saveEditWish($wishId: ID!, $wish: EditWishInput!) {
        editWish(wishId: $wishId, wish: $wish) {
            id
            wishId
            title
            description
            url
            image
            isDraft
            createdAt
        }
    }
`);

import type { ReactElement } from 'react';

export function SiteIcon(
    {
        size = 20
    }: {
        size?: number;
    } = {}
): ReactElement {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            style={{fill: 'currentColor'}}
        >
            <rect
                id="rect863"
                width="16"
                height="5"
                x="2"
                y="15"
            />
            <path
                d="M 4,0 0,4 H 20 L 16,0 Z m 1,1 h 5 V 2 H 4 Z"
                id="path865"
            />
            <rect
                id="rect867"
                width="1"
                height="11"
                x="3"
                y="4" />
            <rect
                id="rect869"
                width="1"
                height="11"
                x="16"
                y="4" />
            <path
                d="m 4,7 v 1 h 4 v 2 H 6 l 1,3 h 3 l 1,-3 H 9 V 8 l 1,1 1,-1 1,1 1,-1 h 2 V 7 H 12 L 11,6 10,7 9,6 8,7 Z"
                id="rect871"
            />
            <path
                d="m 15,7 h 4 v 2 h 1 v 1 H 18 V 8 h -3 z"
                id="path873"
            />
        </svg>
    );
}

export const siteIcon = SiteIcon();

import { gql } from '../__generated__/index.js';
import type { ResultTypeOfQuery } from '../utils/ResultTypeOfQuery.js';

//language=graphql
export const getMyWishes = gql(`
    query getMyWishes {
        myWishes {
            id
            createdAt
            modifiedAt
            title
            description
            url
            image
            isDraft
        }
    }
`);

export type MyWish = ResultTypeOfQuery<typeof getMyWishes>['myWishes'][number];

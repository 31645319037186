import { useMutation } from '@apollo/client';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { DraftWishIcon, SaveIcon } from '../assets/index.js';
import { ModalFormRoute, SubmitResultAction } from '../components/ModalFormRoute.js';
import type { FormState } from '../components/WishForm.js';
import { WishForm } from '../components/WishForm.js';
import { createWish } from '../mutations/createWish.js';
import { getMyWishes } from '../queries/getMyWishes.js';
import type { SetError } from '../utils/useErrorState.js';


const defaultFormState: FormState = {
    title: '',
    description: '',
    url: '',
    image: '',
}

export function CreateWishModal(): ReactElement {

    const [doCreateWish] = useMutation(createWish);

    const [formState, setFormState] = useState<FormState>(defaultFormState);
    const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

    const validateAndReturnValid = useCallback((): boolean => {
        const validationErrors: Record<string, string> = {};
        if (!formState.title) {
            validationErrors.title = 'This field is required';
        }
        setValidationErrors(validationErrors);

        return Object.entries(validationErrors).length === 0;
    }, [formState.title]);

    const handleAttemptSubmit = useCallback(async (
        setError: SetError,
        saveAsDraft: boolean,
    ): Promise<SubmitResultAction> => {
        const isValid = validateAndReturnValid();
        if (!isValid) {
            setError('Fix the errors below and try again');
            return SubmitResultAction.STAY_OPEN;
        }

        await doCreateWish({
            refetchQueries: [getMyWishes],
            variables: {
                createWish: {
                    title: formState.title,
                    description: formState.description || null,
                    url: formState.url || null,
                    image: formState.image || null,
                    isDraft: saveAsDraft,
                },
            },
        });

        return SubmitResultAction.CLOSE;
    }, [doCreateWish, formState, validateAndReturnValid]);

    const handleAttemptSubmitDraft = useCallback(async (
        setError: SetError,
    ): Promise<SubmitResultAction> => {
        return await handleAttemptSubmit(setError, true);
    }, [handleAttemptSubmit]);

    const handleAttemptSubmitNondraft = useCallback(async (
        setError: SetError,
    ): Promise<SubmitResultAction> => {
        return await handleAttemptSubmit(setError, false);
    }, [handleAttemptSubmit]);

    return (
        <ModalFormRoute
            actions={[
                {
                    icon: <DraftWishIcon />,
                    label: 'Save as Draft',
                    onClick: handleAttemptSubmitDraft,
                    variant: 'warning',
                },
                {
                    icon: <SaveIcon />,
                    label: 'Create',
                    onClick: handleAttemptSubmitNondraft,
                },
            ]}
            title="New Wish"
        >
            <WishForm
                formState={formState}
                setFormState={setFormState}
                setValidationErrors={setValidationErrors}
                validationErrors={validationErrors}
            />
        </ModalFormRoute>
    );
}

import { gql } from '../__generated__/index.js';
import type { ResultTypeOfQuery } from '../utils/ResultTypeOfQuery.js';

//language=graphql
export const lookupGroupInvite = gql(`
    query lookupGroupInvite($inviteId: ID!) {
        previewGroupInvite(inviteId: $inviteId) {
            inviteId
            groupId
            groupName
            inviterId
            inviterGivenName
            inviterSurname
            inviteeEmail
        }
    }
`);

export type GroupInvite = NonNullable<ResultTypeOfQuery<typeof lookupGroupInvite>['previewGroupInvite']>;

import type { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { IfAuthenticated } from './components/IfAuthenticated.js';
import { CreateGiftModal } from './pages/CreateGiftModal.js';
import { CreateGroupModal } from './pages/CreateGroupModal.js';
import { CreateWishModal } from './pages/CreateWishModal.js';
import { EditGiftModal } from './pages/EditGiftModal.js';
import { EditWishModal } from './pages/EditWishModal.js';
import { FriendWishlists } from './pages/FriendWishlists.js';
import { JoinGroupModal } from './pages/JoinGroupModal.js';
import { LoginPage } from './pages/LoginPage.js';
import { MyWishlist } from './pages/MyWishlist.js';
import { RedirectCallback } from './pages/RedirectCallback.js';
import { ShoppingList } from './pages/ShoppingList.js';
import {
    CREATE_GIFT,
    CREATE_GROUP,
    CREATE_WISH,
    EDIT_GIFT,
    EDIT_WISH,
    FRIEND_WISHLIST_CREATE_GIFT,
    FRIEND_WISHLIST_EDIT_GIFT,
    FRIEND_WISHLISTS,
    GROUPS_ROOT,
    HOME,
    JOIN_GROUP,
    LOGIN,
    MY_WISHLIST, REDIRECT_CALLBACK,
    SHOPPING_LIST,
} from './paths.js';
import { autoRoute } from './utils/autoRoute.js';
import { FollowRedirectFromLogin, RedirectToLogin } from './utils/loginRedirect.js';

const anonymousRoutes = (
    <Routes>
        <Route
            element={<RedirectCallback />}
            path={REDIRECT_CALLBACK}
        />

        <Route
            path={LOGIN}
            element={
                <IfAuthenticated else={<LoginPage />}>
                    <Navigate to={HOME} replace />
                </IfAuthenticated>
            }
        />
        <Route path="*" element={<RedirectToLogin />} />
    </Routes>
);

const authenticatedRoutes = (
    <Routes>
        <Route
            element={<RedirectCallback />}
            path={REDIRECT_CALLBACK}
        />

        <Route path={MY_WISHLIST} element={<MyWishlist />}>
            <Route path={CREATE_WISH} element={<CreateWishModal />} />
            {autoRoute({ path: EDIT_WISH, component: EditWishModal })}
        </Route>

        <Route path={GROUPS_ROOT}>
            {autoRoute({ path: CREATE_GROUP, component: CreateGroupModal })}
            {autoRoute({ path: FRIEND_WISHLISTS, component: FriendWishlists, additionalProps: { fudge: 3 as const }, children: [
                    autoRoute({ path: FRIEND_WISHLIST_CREATE_GIFT, component: CreateGiftModal }),
                    autoRoute({ path: FRIEND_WISHLIST_EDIT_GIFT, component: EditGiftModal }),
                ] })}
        </Route>

        <Route path={SHOPPING_LIST} element={<ShoppingList />}>
            {autoRoute({ path: CREATE_GIFT, component: CreateGiftModal })}
            {autoRoute({ path: EDIT_GIFT, component: EditGiftModal })}
        </Route>

        {autoRoute({ path: JOIN_GROUP, component: JoinGroupModal })}

        <Route path={LOGIN} element={<FollowRedirectFromLogin />} />

        <Route path="*" element={<Navigate to={MY_WISHLIST} replace />} />
    </Routes>
);

export function AppRoutes(): ReactElement {

    return (
        <IfAuthenticated else={anonymousRoutes}>
            {authenticatedRoutes}
        </IfAuthenticated>
    );
}

import type { ReactElement, ReactNode } from 'react';
import { isLoggedIn, useAuthState } from '../AuthProvider.js';

export function IfAuthenticated(
    {
        children,
        else: elseElement,
    }: {
        children: ReactNode;
        else?: ReactNode;
    },
): ReactElement {
    const authentication = useAuthState();
    if (isLoggedIn(authentication)) return <>{children}</>;
    return <>{elseElement}</>;
}

import { useMutation } from '@apollo/client';
import type { ReactElement } from 'react';
import { useCallback, useState } from 'react';
import { SaveIcon } from '../assets/index.js';
import type { FormState } from '../components/GiftForm.js';
import { GiftForm } from '../components/GiftForm.js';
import { ModalFormRoute, SubmitResultAction } from '../components/ModalFormRoute.js';
import { createGift } from '../mutations/createGift.js';
import { getGroupDetails } from '../queries/getGroupDetails.js';
import { getMyGifts } from '../queries/getMyGifts.js';

export function CreateGiftModal(): ReactElement {
    const [doCreateGift] = useMutation(createGift);

    const [formState, setFormState] = useState<FormState>(() => {
        const queryParams = new URLSearchParams(window.location.search);
        return {
            title: queryParams.get('title') ?? '',
            comments: queryParams.get('comments') ?? '',
            recipientId: queryParams.get('recipientId') ?? '',
            wishId: queryParams.get('wishId') ?? '',
        };
    });
    const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

    const validateAndReturnValid = useCallback((): boolean => {
        const validationErrors: Record<string, string> = {};
        if (!formState.title) {
            validationErrors.title = 'This field is required';
        }
        setValidationErrors(validationErrors);

        return Object.entries(validationErrors).length === 0;
    }, [formState.title]);
    const handleAttemptSubmit = useCallback(async (
        setError: (error: string | null) => void
    ): Promise<SubmitResultAction> => {
        const isValid = validateAndReturnValid();
        if (!isValid) {
            setError('Fix the errors below and try again');
            return SubmitResultAction.STAY_OPEN;
        }

        const result = await doCreateGift({
            refetchQueries: [getMyGifts, getGroupDetails],
            variables: {
                createGift: {
                    title: formState.title,
                    comments: formState.comments || null,
                    recipientId: formState.recipientId || null,
                    wishId: formState.wishId || null,
                },
            },
        });

        const id = result.data?.createGift?.id;
        if (!id) {
            if (result.errors && result.errors.length > 0) {
                const messages = result.errors.map(e => e.message).join('\n');
                setError('Error creating gift:\n' + messages)
            } else {
                setError('Unknown error creating gift')
            }
            return SubmitResultAction.STAY_OPEN;
        }

        return SubmitResultAction.CLOSE;
    }, [doCreateGift, formState.comments, formState.recipientId, formState.title, formState.wishId, validateAndReturnValid]);

    return (
        <ModalFormRoute
            actions={[
                {
                    icon: <SaveIcon />,
                    label: 'Create',
                    onClick: handleAttemptSubmit,
                },
            ]}
            title="New Gift"
        >
            <GiftForm
                formState={formState}
                setFormState={setFormState}
                setValidationErrors={setValidationErrors}
                validationErrors={validationErrors}
            />
        </ModalFormRoute>
    );
}

import * as React from 'react';
import { forwardRef, type ForwardRefExoticComponent, type RefAttributes, useCallback } from 'react';
import { NavLink, type NavLinkProps } from 'react-router-dom';

export interface RouterNavLinkProps extends Omit<NavLinkProps, 'className' | 'style' | 'children'> {
    activeClassName?: string;
    activeStyle?: React.CSSProperties;
    children: React.ReactNode,
    className?: string;
    style?: React.CSSProperties;
}

/**
 * Compatibility shim between React-router's NavLink v6 component and the version it shipped with in v5
 *
 * React Router keeps changing their API every major version and it's getting on my nerves
 *
 * This is necessary because they replaced activeClassName/activeStyle with optional callback functions
 * (invoked with flags signifying whether the route is "active")
 * but those callback functions are incompatible with React-Bootstrap's own `<Nav.Link as=>` properties
 *
 * Based on: https://reactrouter.com/en/main/upgrading/v5#remove-activeclassname-and-activestyle-props-from-navlink-
 * Archive.org mirror: https://web.archive.org/web/20230711000759/https://reactrouter.com/en/main/upgrading/v5#remove-activeclassname-and-activestyle-props-from-navlink-
 *
 */
export const RouterNavLink: ForwardRefExoticComponent<RouterNavLinkProps & RefAttributes<HTMLAnchorElement>> = forwardRef(
    function RouterNavLink(
        {
            activeClassName,
            activeStyle,
            className:
            permanentClassName,
            style:
            permanentStyle,
            ...props
        },
        ref,
    ) {
        const className: NavLinkProps['className'] = useCallback(({ isActive }: {
            isActive: boolean;
            isPending: boolean;
        }) => {
            return isActive
                ? permanentClassName
                    ? activeClassName
                        ? `${permanentClassName} ${activeClassName}`
                        : permanentClassName
                    : activeClassName
                : permanentClassName
        }, [activeClassName, permanentClassName]);

        const style: NavLinkProps['style'] = useCallback(({ isActive }: {
            isActive: boolean;
            isPending: boolean;
        }) => {
            return isActive
                ? permanentStyle
                    ? activeStyle
                        ? { ...permanentStyle, ...activeStyle }
                        : permanentStyle
                    : activeStyle
                : permanentStyle
        }, [activeStyle, permanentStyle]);

        return (
            <NavLink
                ref={ref}
                {...props}
                className={className}
                style={style}
            />
        );
    },
);

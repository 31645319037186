import { gql } from '../__generated__/index.js';
import type { ResultTypeOfQuery } from '../utils/ResultTypeOfQuery.js';

//language=graphql
export const getFriendWishes = gql(`
    query getFriendWishes($userId: ID!) {
        friend(userId: $userId) {
            id
            wishes {
                id
                title
                isClaimed
                isClaimedBySomeoneElse
            }
        }
    }
`);

export type Wish = NonNullable<ResultTypeOfQuery<typeof getFriendWishes>['friend']>['wishes'][number];

import { gql } from '../__generated__/index.js';
import type { ResultTypeOfQuery } from '../utils/ResultTypeOfQuery.js';

//language=graphql
export const listFriends = gql(`
    query listFriends {
        friends {
            id,
            givenName,
            surname,
        }
    }
`);

export type Friend = ResultTypeOfQuery<typeof listFriends>['friends'][number];

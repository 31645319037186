import type { Context } from 'react';
import { createContext, useContext } from 'react';

export const USAGE_OUTSIDE_PROVIDER = Symbol('Usage outside provider');

export type ProviderAssertionContextType<T> = T | typeof USAGE_OUTSIDE_PROVIDER;

export function createContextWithProviderAssertion<T>(displayName: string): Context<ProviderAssertionContextType<T>> {
    const ret = createContext<T | typeof USAGE_OUTSIDE_PROVIDER>(USAGE_OUTSIDE_PROVIDER);
    ret.displayName = displayName;
    return ret;
}

export function assertUsageInsideProvider<T>(context: Context<ProviderAssertionContextType<T>>, ret: ProviderAssertionContextType<T>): T {
    if (ret === USAGE_OUTSIDE_PROVIDER) {
        throw new Error('Cannot use ' + context.displayName + ' outside its provider');
    }
    return ret;
}

export function useContextWithProviderAssertion<T>(context: Context<ProviderAssertionContextType<T>>): T {
    const ret = useContext(context);
    return assertUsageInsideProvider(context, ret);
}

import { gql } from '../__generated__/index.js';
import type { ResultTypeOfQuery } from '../utils/ResultTypeOfQuery.js';

//language=graphql
export const getGroupDetails = gql(`
    query getGroupDetails ($groupId: ID!) {
        group(groupId: $groupId) {
            id
            groupName
            otherMembers {
                id
                givenName
                surname
                wishes {
                    id
                    createdAt
                    modifiedAt
                    title
                    description
                    url
                    image
                    isClaimed
                    isClaimedBySomeoneElse
                }
            }
        }
        myGifts {
            id
            wishId
            recipientId
            title
            comments
            createdAt
            modifiedAt
            originalWish {
                id
                createdAt
                modifiedAt
                title
                description
                url
                image
            }
        }
    }
`);

export type Friend = NonNullable<ResultTypeOfQuery<typeof getGroupDetails>['group']>['otherMembers'][number];
export type FriendWish = Friend['wishes'][number];
export type MyGift = ResultTypeOfQuery<typeof getGroupDetails>['myGifts'][number];

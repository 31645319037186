import type { ReactElement, ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import { COPYRIGHT_YEAR } from './constants.js';
import { MainNav } from './MainNav.js';

export function App(
    {
        children
    }: {
        children: ReactNode
    }
): ReactElement {
    return <>
        <MainNav />
        <Container className="mt-3 mb-5">
            {children}
        </Container>
        <Container className="text-muted text-center">
            <p>Copyright {COPYRIGHT_YEAR} Karl Wingblade - <a href="mailto:incoming+karlrwjohnson-wishing-well-30704169-2jztjay8bukd8h7jmyopqf2ak-issue@incoming.gitlab.com">Contact</a></p>
        </Container>
    </>
}

import { useMutation, useQuery } from '@apollo/client';
import { preventDefault } from '@karlrwjohnson/libkarl/esm/react/DefaultPreventable.js';
import type { ReactElement, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import type { MyWish } from '../__generated__/graphql.js';
import { DraftWishIcon, SaveIcon } from '../assets/index.js';
import { ModalFormRoute, SubmitResultAction } from '../components/ModalFormRoute.js';
import type { FormState } from '../components/WishForm.js';
import { WishForm } from '../components/WishForm.js';
import { deleteWish } from '../mutations/deleteWish.js';
import { getMyWish } from '../queries/getMyWish.js';
import { getMyWishes } from '../queries/getMyWishes.js';
import { saveEditWish } from '../mutations/saveEditWish.js';
import type { SetError } from '../utils/useErrorState.js';

export function EditWishModal({wishId}: { wishId: string }): ReactElement {

    const [doDeleteWish] = useMutation(deleteWish);
    const [doSaveEditWish] = useMutation(saveEditWish, {
        refetchQueries: [getMyWishes],
    });
    const originalWish = useQuery(getMyWish, { variables: { wishId } });

    const [formState, setFormState] = useState<FormState>({
        title: '',
        description: '',
        url: '',
        image: '',
    });

    useEffect(() => {
        if (!originalWish.data?.getMyWish) return;
        setFormState({
            title: originalWish.data.getMyWish.title,
            description: originalWish.data.getMyWish.description || '',
            url: originalWish.data.getMyWish.url || '',
            image: originalWish.data.getMyWish.image || '',
        })
    }, [originalWish.data]);

    const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

    const validateAndReturnValid = useCallback((): boolean => {
        const validationErrors: Record<string, string> = {};
        if (!formState.title) {
            validationErrors.title = 'This field is required';
        }
        setValidationErrors(validationErrors);

        return Object.entries(validationErrors).length === 0;
    }, [formState]);

    const handleAttemptSubmit = useCallback(async (
        setError: SetError,
        saveAsDraft: boolean,
    ): Promise<SubmitResultAction> => {
        const isValid = validateAndReturnValid();
        if (!isValid) {
            setError('Fix the errors below and try again');
            return SubmitResultAction.STAY_OPEN;
        }

        const now = new Date().toISOString();

        await doSaveEditWish({
            optimisticResponse: {
                editWish: {
                    id: wishId,
                    wishId: wishId,
                    isDraft: saveAsDraft,
                    title: formState.title,
                    description: formState.description,
                    url: formState.url,
                    image: formState.image,
                    createdAt: originalWish.data?.getMyWish?.createdAt ?? now,
                } satisfies Partial<MyWish>,
            },
            variables: {
                wishId,
                wish: {
                    title: formState.title,
                    description: formState.description || null,
                    url: formState.url || null,
                    image: formState.image || null,
                    isDraft: saveAsDraft,
                },
            },
        });

        return SubmitResultAction.CLOSE;
    }, [doSaveEditWish, wishId, formState.title, formState.description, formState.url, formState.image, originalWish.data?.getMyWish?.createdAt, validateAndReturnValid]);

    const handleAttemptSubmitDraft = useCallback(async (
        setError: SetError,
    ): Promise<SubmitResultAction> => {
        return await handleAttemptSubmit(setError, true);
    }, [handleAttemptSubmit]);

    const handleAttemptSubmitNondraft = useCallback(async (
        setError: SetError,
    ): Promise<SubmitResultAction> => {
        return await handleAttemptSubmit(setError, false);
    }, [handleAttemptSubmit]);

    const handleDelete = useCallback(async () => {
        await doDeleteWish({
            refetchQueries: [getMyWishes],
            variables: {
                wishId
            },
        });
        return SubmitResultAction.CLOSE;
    }, [doDeleteWish, wishId]);

    let form: ReactNode;
    if (originalWish.loading) {
        form = <p>Loading...</p>;
    } else {
        form = (
            <WishForm
                formState={formState}
                setFormState={setFormState}
                setValidationErrors={setValidationErrors}
                validationErrors={validationErrors}
            />
        );
    }

    return (
        <ModalFormRoute
            actions={originalWish.data?.getMyWish ? [
                {
                    confirm: {
                        cancelLabel: 'No, keep',
                        confirmLabel: 'Yes, delete',
                        confirmVariant: 'danger',
                        messageBody: 'Are you sure you want to do this?',
                        messageTitle: 'Delete Wish',
                    },
                    label: 'Delete Wish',
                    onClick: handleDelete,
                    variant: 'outline-danger',
                },
                {
                    confirm: originalWish.data.getMyWish.isDraft ? undefined : {
                        cancelLabel: 'Never mind',
                        confirmLabel: 'Convert to Draft',
                        confirmVariant: 'warning',
                        messageBody: <>
                            Converting a wish to a draft hides it from everyone else.<br />
                            If someone has already claimed it, it will look like you deleted the wish.
                        </>,
                        messageTitle: 'Convert to Draft',
                    },
                    icon: <DraftWishIcon />,
                    label: originalWish.data.getMyWish.isDraft ? 'Update Draft' : 'Convert to Draft',
                    onClick: handleAttemptSubmitDraft,
                    variant: 'warning',
                },
                {
                    icon: <SaveIcon />,
                    label: originalWish.data.getMyWish.isDraft ? 'Make Public' : 'Update',
                    onClick: handleAttemptSubmitNondraft,
                },
            ] : []}
            title={<>
                Edit Wish

                {originalWish.data?.getMyWish?.isDraft &&
                <span className="fs-6">
                    <OverlayTrigger
                        overlay={<Tooltip>This wish is currently a draft. Drafts are invisible to others.</Tooltip>}
                        placement="bottom"
                        trigger={['focus', 'hover']}
                    >
                        <Badge
                            as="abbr"
                            bg="warning"
                            text="dark"
                            className="ms-3"
                            onClick={preventDefault}
                            tabIndex={0}
                            data-bs-original-title="This wish is currently a draft. Drafts are invisible to others."
                        >
                            <DraftWishIcon /> Draft
                        </Badge>
                    </OverlayTrigger>
                </span>
                }
            </>}
        >
            {form}
        </ModalFormRoute>
    );
}

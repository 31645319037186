import { map as mapRight } from 'fp-ts/lib/Either.js';
import { pipe } from 'fp-ts/lib/function.js';
import * as t from 'io-ts';
import type { UserManager } from 'oidc-client-ts';
import { type ReactElement, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import {
    authStateContext,
    isLoggedIn,
    LOADING_SESSION_FROM_REDIRECT,
    NOT_LOGGED_IN,
    useUserManager,
} from '../AuthProvider.js';
import { LOGIN } from '../paths.js';
import { useContextWithProviderAssertion } from '../utils/createContextWithProviderAssertion.js';

/**
 * State object we want to attach to the oidc-client-ts User object during the login round-trip
 */
export const UserState = t.type({
    redirect: t.string,
}, 'UserState');
export type UserState = t.TypeOf<typeof UserState>;

export function signIn(userManager: UserManager): void {
    userManager.signinRedirect({
        state: ({
            redirect: location.pathname + location.search + location.hash,
        }) satisfies UserState,
    });
}

export function signOut(userManager: UserManager): void {
    userManager.signoutSilent({
        post_logout_redirect_uri: window.location.origin + LOGIN,
    });
}
export function useLoginFunction(): (() => void) {
    const userManager = useUserManager();
    return useMemo(() => () => signIn(userManager), [userManager]);
}

export function useLogoutFunction(): (() => void) {
    const userManager = useUserManager();
    return useMemo(() => () => signOut(userManager), [userManager]);
}

/**
 * Finish the OAuth2/OpenID Connect login flow, storing the resulting user object in context
 * and redirecting to the page we were on previously.
 */
export function RedirectCallback(): ReactElement {
    const [, setState, userManager] = useContextWithProviderAssertion(authStateContext);
    const navigate = useNavigate();

    useEffect(() => {
        setState(prev =>
            isLoggedIn(prev)
                ? prev
                : LOADING_SESSION_FROM_REDIRECT
        );
        userManager.signinRedirectCallback().then(
            user => {
                setState(user);

                pipe(
                    UserState.decode(user.state),
                    mapRight(state => navigate(state.redirect)),
                );
            },
            err => {
                console.error('Error logging in', err);
                setState(NOT_LOGGED_IN);
            },
        );
    }, [navigate, setState, userManager]);

    return (
        <div>
            <p>Loading session...</p>
        </div>
    );
}

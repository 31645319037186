import type { ParamParseKey } from '@remix-run/router';
import type { ReactElement, ReactNode } from 'react';
import { Outlet, Route, useMatch } from 'react-router';

/**
 * Generate a <Route> component that passes route matches down to the component through props.
 *
 * This imitates an earlier version of React-Router's API before they switched from component= to element=
 *
 * The core idea is that we should have ONE place in the code that knows both 1) the path being matched, and
 * 2) extracts variables from the match (useMatch) to pass to the element.
 *
 * Having a "routes" file know what the path is but having the component be responsible for correctly guessing
 * what path it's trying to match on -- seems utterly silly. Silly in that it's prone to errors.
 *
 * We use Typescript to verify that the component's props match the useMatch() parameters.
 *
 * @param Component -- function component to render
 * @param path -- const-string of the path
 * @param additionalProps -- extra props to pass in that don't appear in the Path matches
 * @param children -- child routes.
 *      If `children` is defined, then the Component must accept a `children` prop.
 *      This reverses a decision React-Router made in v6 to force container elements to magically "know" they're a container
 *      (outside of the type system!) and render an `<Outlet />` element.
 *      Passing a `children` prop is safer because it uses the type system to force the component to deal with the children
 */
export function autoRoute<
    TPath extends string,
    TChildren extends undefined | ReactNode | ReactNode[],
    TAdditional extends {},
    TComponent extends (
        props: Record<ParamParseKey<TPath>, string>
            & TAdditional
            & (TChildren extends ReactNode | ReactNode[] ? { children: ReactNode } : {})
    ) => ReactElement
>(
    {
        component: Component,
        path,
        additionalProps,
        children,
    }: {
        component: TComponent
        path: TPath,
        children?: TChildren,
        additionalProps?: TAdditional,
    },
): ReactElement {
    function HOCElement(): ReactElement {
        const match = useMatch({ path, end: false });

        if (!match) {
            // Should not happen. Only happened locally when I used useMatch() incorrectly.
            // I'm leaving the fallback message here because React Router likes to make breaking changes
            // between major versions and I don't trust them to do the right thing anymore.
            return <p>Something is weird; the router is trying to render the route for path <code>{path}</code>
                but useMatch() is returning <code>{JSON.stringify(match)}</code></p>;
        }

        const combinedProps = { ...match.params, ...additionalProps };
        if (children) {
            return <Component {...combinedProps as any}><Outlet /></Component>;
        } else {
            return <Component {...combinedProps as any} />;
        }
    }

    return (
        <Route path={path} element={<HOCElement/>}>
            {children}
        </Route>
    );
}

import { useMutation, useQuery } from '@apollo/client';
import { preventDefault } from '@karlrwjohnson/libkarl/esm/react/DefaultPreventable.js';
import type { ReactElement, ReactNode } from 'react';
import { useCallback } from 'react';
import { Alert, Badge, Button, Card, ListGroup, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import type { Variant } from 'react-bootstrap/types';
import { useNavigate } from 'react-router';
import { generatePath, Link, useParams } from 'react-router-dom';
import {
    AddFriendIcon,
    ClaimedWishIcon,
    ClaimWishIcon,
    EmptyWell,
    GiftIcon,
    LeaveGroupIcon,
    PlusIcon,
    WishlistIcon,
} from '../assets/index.js';
import { DeleteButtonWithConfirm } from '../components/DeleteButtonWithConfirm.js';
import { DiffView } from '../components/DiffView.js';
import { RelativeTimeFormat } from '../components/RelativeTimeFormat.js';
import { FRIEND_WISHLIST_CREATE_GIFT, FRIEND_WISHLIST_EDIT_GIFT, MY_WISHLIST } from '../paths.js';
import { claimFriendWish } from '../mutations/claimFriendWish.js';
import type { Friend, FriendWish, MyGift } from '../queries/getGroupDetails.js';
import { getGroupDetails } from '../queries/getGroupDetails.js';
import { getMyGifts } from '../queries/getMyGifts.js';
import { getMyGroups } from '../queries/getMyGroups.js';
import { leaveGroup } from '../mutations/leaveGroup.js';
import { InviteModal } from './InviteModal.js';

const WISH_UPDATED_BADGE_TOOLTIP = `This wish was updated since you claimed it, so we're highlighting what changed. To hide changes, Edit the gift and click Update.`

export function FriendWishView({ friendWish: wish, myGift }: { friendWish: FriendWish, myGift: MyGift | undefined }): ReactElement {
    const originalWish = myGift?.originalWish;

    const [doClaimWish, { loading: claimingWish }] = useMutation(claimFriendWish);
    const handleClickClaimWish = useCallback(async () => {
        doClaimWish({
            refetchQueries: [getGroupDetails, getMyGifts],
            variables: {
                wishId: wish.id,
            },
        });
    }, [doClaimWish, wish.id]);

    const wasModified = originalWish && (
        wish.title !== originalWish.title ||
        wish.description !== originalWish.description ||
        wish.url !== originalWish.url ||
        wish.image !== originalWish.image
    );

    let variant: Variant | undefined;
    if (wish.isClaimedBySomeoneElse) {
        variant = 'secondary';
    }

    const { groupId = null } = useParams<{ groupId: string }>();

    return (
        <ListGroup.Item
            className="overflow-hidden"
            variant={variant}
        >
            <div className="d-flex flex-column flex-md-row gap-3" style={{ minWidth: 0 }}>
                <div className="flex-md-grow-1 flex-md-shrink-1" style={{ flexBasis: 0, minWidth: 0 }}>
                    <span className="float-end fs-6">
                        {wish.isClaimedBySomeoneElse &&
                            <OverlayTrigger
                                overlay={<Tooltip>Someone else is providing a gift to fulfill this wish</Tooltip>}
                                placement="auto-end"
                                trigger={['focus', 'hover']}
                            >
                                <abbr
                                    className="cursor-help"
                                    onClick={preventDefault}
                                    tabIndex={0}
                                    data-bs-original-title="Someone else is providing a gift to fulfill this wish"
                                >
                                    Taken
                                </abbr>
                            </OverlayTrigger>
                        }
                        {!wish.isClaimedBySomeoneElse && !myGift &&
                            <OverlayTrigger
                                overlay={<Tooltip>Click to tell others that you've got this gift covered</Tooltip>}
                                placement="auto-end"
                                trigger={['focus', 'hover']}
                            >
                                <Button
                                    disabled={claimingWish}
                                    onClick={claimingWish ? undefined : handleClickClaimWish}
                                    size="sm"
                                    variant="outline-primary"
                                >
                                    {claimingWish ?
                                        <Spinner
                                            animation="border"
                                            as="span"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> :
                                        <ClaimWishIcon/>
                                    }&nbsp;
                                    Claim this Wish
                                </Button>
                            </OverlayTrigger>}
                    </span>

                    {wasModified && <span className="fs-6">
                            <OverlayTrigger
                                overlay={<Tooltip>{WISH_UPDATED_BADGE_TOOLTIP}</Tooltip>}
                                placement="right"
                                trigger={['focus', 'hover']}
                            >
                                <Badge
                                    as="abbr"
                                    bg="white"
                                    text="primary"
                                    className="mb-2 border border-primary"
                                    tabIndex={0}
                                    data-bs-original-title={WISH_UPDATED_BADGE_TOOLTIP}
                                >
                                    Updated
                                </Badge>
                            </OverlayTrigger>
                        </span>}

                    <Card.Title>
                        <DiffView oldText={originalWish?.title ?? wish?.title ?? ''} newText={wish.title ?? ''}/>
                    </Card.Title>
                    {wish.description || originalWish?.description ?
                        <p style={{ whiteSpace: 'pre-wrap' }}><DiffView
                            oldText={originalWish?.description ?? wish.description ?? ''}
                            newText={wish.description ?? ''}/></p> :
                        undefined}
                    {wish.url && <p className="text-truncate">Link: <a href={wish.url}>🔗 {wish.url}</a></p>}
                    {wish.image && <Card.Img alt="user-provided image" src={wish.image} variant="bottom"/>}

                    <div className="text-muted" style={{ fontSize: 'smaller' }}>
                        Created <RelativeTimeFormat iso8601Timestamp={wish.createdAt}/>
                        {(wish.modifiedAt && wish.modifiedAt !== wish.createdAt)
                            ? <> and last updated <RelativeTimeFormat iso8601Timestamp={wish.modifiedAt}/></>
                            : <></>
                        }
                    </div>
                </div>
                {myGift && <ListGroup className="flex-md-grow-1 flex-md-shrink-1" style={{ flexBasis: 0 }}>
                    <ListGroup.Item variant="success">
                        <div className="d-flex flex-row align-items-baseline">
                            <p className="flex-grow-1 fw-bold small text-muted">Your gift</p>
                            <div className="d-flex flex-row gap-2 align-items-baseline">
                                <div><ClaimedWishIcon/> Claimed!</div>
                                <OverlayTrigger
                                    overlay={<Tooltip>Click to edit details about this gift</Tooltip>}
                                    placement="auto-end"
                                    trigger={['focus', 'hover']}
                                >
                                    <Link
                                        className="btn btn-success btn-sm"
                                        to={generatePath(FRIEND_WISHLIST_EDIT_GIFT, { giftId: myGift.id, groupId })}
                                    >
                                        Edit Gift
                                    </Link>
                                </OverlayTrigger>
                            </div>
                        </div>

                        <Card.Title>{myGift.title}</Card.Title>

                        {myGift.comments && <p style={{ whiteSpace: 'pre-wrap' }}>{myGift.comments}</p>}

                        <div className="text-muted" style={{ fontSize: 'smaller' }}>
                            Claimed <RelativeTimeFormat iso8601Timestamp={myGift.createdAt}/>
                            {(myGift.modifiedAt && myGift.modifiedAt !== myGift.createdAt)
                                ? <> and last updated <RelativeTimeFormat iso8601Timestamp={myGift.modifiedAt}/></>
                                : <></>
                            }
                        </div>
                    </ListGroup.Item>
                </ListGroup>}
            </div>
        </ListGroup.Item>
    );
}


export function UnrequestedGiftView({ myGift }: { myGift: MyGift }): ReactElement {

    const { groupId = null } = useParams<{ groupId: string }>();

    return (
        <ListGroup.Item variant="success">
            <div className="d-flex flex-row align-items-baseline">
                <p className="flex-grow-1 fw-bold small text-muted">Your gift</p>

                <OverlayTrigger
                    overlay={<Tooltip>Click to edit details about this gift</Tooltip>}
                    placement="auto-end"
                    trigger={['focus', 'hover']}
                >
                    <Link
                        className="btn btn-success btn-sm"
                        to={generatePath(FRIEND_WISHLIST_EDIT_GIFT, { giftId: myGift.id, groupId })}
                    >
                        Edit Gift
                    </Link>
                </OverlayTrigger>
            </div>

            <Card.Title>
                {myGift.title}
            </Card.Title>

            {myGift.comments && <p style={{ whiteSpace: 'pre-wrap' }}>{myGift.comments}</p>}

            <div className="text-muted" style={{ fontSize: 'smaller' }}>
                Created <RelativeTimeFormat iso8601Timestamp={myGift.createdAt}/>
                {(myGift.modifiedAt && myGift.modifiedAt !== myGift.createdAt)
                    ? <> and last updated <RelativeTimeFormat iso8601Timestamp={myGift.modifiedAt}/></>
                    : <></>
                }
            </div>
        </ListGroup.Item>
    );
}

export function FriendWishListView({ friend, myGifts }: { friend: Friend, myGifts: MyGift[] }): ReactElement {
    const unrequestedGifts = myGifts.filter(gift => gift.recipientId === friend.id && !gift.originalWish?.id);

    const myGiftCount = myGifts.filter(gift => gift.recipientId === friend.id).length;
    const claimedWishCount = friend.wishes.filter(wish => wish.isClaimed).length;
    const unclaimedWishCount = friend.wishes.length - claimedWishCount;

    const { groupId = null } = useParams<{ groupId: string }>();

    return (
        <>
            <div
                className="mt-3 position-sticky bg-white d-flex flex-row gap-3"
                style={{
                    boxShadow: '0 0 1rem 1rem white',
                    top: '70px',
                    zIndex: 1,
                }}
            >
                <h3 className="flex-grow-1 flex-shrink-1 text-truncate">{friend.givenName} {friend.surname}</h3>
                <OverlayTrigger
                    overlay={<Tooltip>Number of gifts you're planning to give this person</Tooltip>}
                    placement="auto-end"
                    trigger={['focus', 'hover']}
                >
                    <abbr
                        className={`flex-shrink-0 ${myGiftCount === 0 ? 'text-warning' : ''}`}
                        data-bs-original-title="Number of gifts you're planning to give this person"
                        tabIndex={0}
                    >
                        <GiftIcon /> {myGiftCount}
                        <span className="d-md-inline d-none">&nbsp;gift{myGiftCount === 1 ? '' : 's'}</span>
                    </abbr>
                </OverlayTrigger>
                <OverlayTrigger
                    overlay={<Tooltip>Number of unclaimed wishes</Tooltip>}
                    placement="auto-end"
                    trigger={['focus', 'hover']}
                >
                    <abbr
                        className={`flex-shrink-0 ${unclaimedWishCount === 0 ? '' : 'text-warning'}`}
                        data-bs-original-title="Number of unclaimed wishes"
                        tabIndex={0}
                    >
                        <WishlistIcon /> {unclaimedWishCount}
                        <span className="d-md-inline d-none">&nbsp;unclaimed wish{unclaimedWishCount === 1 ? '' : 'es'}</span>
                    </abbr>
                </OverlayTrigger>
            </div>

            {friend.wishes.length === 0 ?
                <Alert className="border-secondary my-1" variant="light" style={{ borderStyle: 'dashed' }}>
                    No wishes found
                </Alert> :
                <ListGroup className="my-3 shadow">
                    {friend.wishes.map(wish => <FriendWishView key={wish.id} friendWish={wish} myGift={myGifts.find(gift => gift.originalWish?.id === wish.id)} />)}
                </ListGroup>
            }
            {unrequestedGifts.length > 0 &&
                <div className="ms-5">
                    <div className="d-flex align-items-baseline gap-3 text-muted my-3">
                        <h5>Other gifts</h5>
                        <OverlayTrigger
                            overlay={<Tooltip>These gifts aren't tied to a wish. If they used to be tied to a wish, then the wish may have been deleted.</Tooltip>}
                        >
                            <abbr
                                data-bs-original-title=""
                                tabIndex={0}
                            >
                                (what's this?)
                            </abbr>
                        </OverlayTrigger>
                    </div>
                    <ListGroup className="my-3 shadow">
                        {unrequestedGifts.map(gift => <UnrequestedGiftView key={gift.id} myGift={gift} />)}
                    </ListGroup>
                </div>}
            <div className="ms-5">
                <Link
                    className="btn btn-link text-decoration-none"
                    to={`${generatePath(FRIEND_WISHLIST_CREATE_GIFT, { groupId })}?recipientId=${friend.id}`}
                >
                    <PlusIcon /> Track a gift that {friend.givenName} hasn't wished for
                </Link>
            </div>
        </>
    )
}

export function GiftIdeasToNoOne({ myGifts }: { myGifts: MyGift[] }): ReactElement {
    const orphanedGifts = myGifts.filter(gift => !gift?.recipientId)

    if (!orphanedGifts.length) return <></>;

    return (
        <>
            <h3 className="text-danger mt-4">No Recipient</h3>
            <div className="ms-5">
                <ListGroup className="my-3 shadow">
                    {orphanedGifts.map(gift => <UnrequestedGiftView key={gift.id} myGift={gift} />)}
                </ListGroup>
            </div>
        </>
    );
}

export function FriendWishlists(
    {
        children,
        groupId,
    }: {
        children: ReactNode,
        groupId: string,
    }
): ReactElement {
    // Separate the modals (`children`) from the rest of the page so that when the modals submit and cause
    // the parent query to re-fetch, the parent entering the "loading" state shouldn't unmount the modals
    return (
        <>
            <FriendWishlistsContent groupId={groupId} />
            {children}
        </>
    );
}

export function FriendWishlistsContent(
    {
        groupId
    }: {
        groupId: string
    }
): ReactElement {
    const [doLeaveGroup] = useMutation(leaveGroup);
    const { data, error, loading } = useQuery(getGroupDetails, {
        variables: {
            groupId,
        },
    });

    const navigate = useNavigate();

    const handleLeaveGroup = useCallback(async () => {
        await doLeaveGroup({
            refetchQueries: [getMyGroups],
            variables: { groupId },
        });
        navigate(MY_WISHLIST, { replace: true });
    }, [doLeaveGroup, groupId, navigate]);

    if (loading && !data) {
        return <p className="mt-5 text-center"><Spinner animation="border" /></p>
    }

    const group = data?.group;
    if (!data || !group) {
        if (error) {
            return (
                <Alert variant="danger">
                    <p>Errors loading group: {error.message}</p>
                    {error.clientErrors.map((e, i) => <p key={i}>{e.message}</p>)}
                </Alert>
            );
        }
        return (
            <Alert variant="danger">
                Group did not load.
            </Alert>
        );
    }

    return (
        <>
            <div className="d-flex align-items-baseline">
                <h1 className="flex-grow-1">{group.groupName}</h1>
                <DeleteButtonWithConfirm
                    buttonClassName="text-danger"
                    buttonIcon={<LeaveGroupIcon />}
                    buttonLabel={<span className="d-none d-md-inline">Leave Group</span>}
                    buttonVariant="link"
                    cancelButtonLabel="No, Stay"
                    confirmButtonLabel="Yes, Leave"
                    confirmMessageTitle="Leave Group?"
                    confirmMessageBody="You won't be able to re-join unless someone invites you back"
                    onConfirm={handleLeaveGroup}
                    placement="bottom"
                />
                <InviteModal groupId={groupId} />
            </div>
            <hr />

            {!(group.otherMembers.length > 0) && <>
                <div className="text-center my-4">
                    <h3 className="mb-3">{`Where is everyone?`}</h3>
                    <p>
                        You're the only person in this group.<br />
                        Invite friends and family using the "<AddFriendIcon /> Invite a Friend" button.
                    </p>
                </div>
                <EmptyWell
                    className="my-5 mx-auto d-block"
                    style={{
                        width: 229,
                        height: 342,
                    }}
                />
            </>}

            {group.otherMembers.map(friend => <FriendWishListView key={friend.id} friend={friend} myGifts={data.myGifts} />)}

            <GiftIdeasToNoOne myGifts={data.myGifts} />
        </>
    );
}

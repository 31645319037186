import { raise } from '@karlrwjohnson/libkarl/cjs/raise.js';
import { isDefined } from '@karlrwjohnson/libkarl/esm/isDefined.js';
import type { JSX, ReactElement, ReactNode } from 'react';
import { createElement, memo } from 'react';

function kebabToCamelCase(str: string): string {
    return str.replaceAll(/-(\w?)/g, (_wholeMatch, nextChar) => nextChar.toUpperCase());
}

function generateReactElementFromElement(element: Element, index: number): ReactElement {
    const tag = element.tagName;

    const props: Record<string, unknown> = Object.fromEntries(
        element.getAttributeNames()
            .map(name => [kebabToCamelCase(name), element.getAttribute(name)])
    );

    if ('style' in props && typeof props.style === 'string') {
        props.style = Object.fromEntries(
            props.style
                .split(/;\s*/)
                .map((cssProp): [string, string] | null => {
                    const match = cssProp.match(/^(.+?)\s*:\s*(.+)$/);
                    if (!match) return null;
                    const [,key, value] = match;
                    const reactifiedKey = kebabToCamelCase(key);
                    return [reactifiedKey, value];
                })
                .filter(isDefined)
        );
    }

    // Add the index because this can be an element in an array
    // and we don't want React to throw warnings out that the key is missing
    props.key = index;

    const children: ReactNode[] = Array.from(element.childNodes, generateReactNodeFromNode);

    return createElement(tag, props, ...children);
}

function generateReactNodeFromNode(node: Node, index: number): ReactNode {
    if (node instanceof Text) {
        return node.data;
    } else if (node instanceof Element) {
        return generateReactElementFromElement(node, index)
    } else {
        return undefined;
    }
}

function stringifyViewBox({ baseVal }: SVGAnimatedRect): string {
    return `${baseVal.x} ${baseVal.y} ${baseVal.width} ${baseVal.height}`;
}

const parser = new DOMParser();

export type Icon = (props: JSX.IntrinsicElements['svg']) => ReactNode;

export function loadSvgAsJsx(svgString: string): Icon {
    const parsedDoc = parser.parseFromString(svgString, 'image/svg+xml');
    const rootElement = parsedDoc.documentElement as unknown;
    if (!(rootElement instanceof SVGSVGElement)) {
        raise('Expected root element to be an SVGElement, got ' + rootElement);
    }

    const viewBox = rootElement.viewBox
        ? stringifyViewBox(rootElement.viewBox)
        : undefined;

    const children = Array.from(rootElement.children, generateReactNodeFromNode);

    return memo(function IconImpl(props: JSX.IntrinsicElements['svg']): ReactElement {
        const newProps: JSX.IntrinsicElements['svg'] = {
            viewBox,
            ...props,
            style: {
                fill: 'currentColor',
                height: '1em',
                ...props.style,
            },
        };
        return <svg {...newProps}>{...children}</svg>;
    });
}

import { findLongestCommonSubsequence, prepareTextForDiff } from '@karlrwjohnson/libkarl/esm/diff/index.js';
import { createElement, Fragment, memo, type ReactElement } from 'react';

function DiffViewImpl(
    {
        oldText,
        newText,
    }: {
        oldText: string;
        newText: string;
    }
): ReactElement {
    if (oldText === newText) {
        return <>{oldText}</>;
    }

    // I wrote a 3-way diffing algorithm awhile back and I'm re-using it here.
    // The data structure is kinda wonky because I was trying to be efficient with memory.
    // The return type "LCSResult" is a linked list of word indices in the original text.
    // (Thus, `null` indicates nothing shared in common)
    // To make things weirder, the list is returned in reverse order (references point to the
    // PREVIOUS entry)
    const oldPrepared = prepareTextForDiff(oldText);
    const newPrepared = prepareTextForDiff(newText);
    let lcs = findLongestCommonSubsequence(oldPrepared, newPrepared);

    const reversedChildren: Array<string | ReactElement> = [];

    // Iteration goes back to front
    let nextOldWordIdx = oldPrepared.originalWords.length - 1;
    let nextNewWordIdx = newPrepared.originalWords.length - 1;
    while (lcs) {
        if (nextNewWordIdx > lcs.rightIdx) {
            const word = newPrepared.originalWords[nextNewWordIdx];
            console.log('push new word', JSON.stringify(word), 'nextOldWordIdx =', nextOldWordIdx, 'nextNewWordIdx =', nextNewWordIdx, 'lcs =', lcs);
            reversedChildren.push(<ins className="text-success" style={{ textDecorationStyle: 'double' }} title="Addition">{word}</ins>);
            --nextNewWordIdx;
        } else if (nextOldWordIdx > lcs.leftIdx) {
            const word = oldPrepared.originalWords[nextOldWordIdx];
            console.log('push old word', JSON.stringify(word), 'nextOldWordIdx =', nextOldWordIdx, 'nextNewWordIdx =', nextNewWordIdx, 'lcs =', lcs);
            reversedChildren.push(<del className="text-danger" style={{ opacity: 0.5 }} title="Deletion">{word}</del>);
            --nextOldWordIdx;
        } else {
            const word = newPrepared.originalWords[nextNewWordIdx];
            console.log('push shared word', JSON.stringify(word), 'nextOldWordIdx =', nextOldWordIdx, 'nextNewWordIdx =', nextNewWordIdx, 'lcs =', lcs);
            reversedChildren.push(word);
            --nextNewWordIdx;
            --nextOldWordIdx;
            lcs = lcs.prev;
        }
    }
    while (nextNewWordIdx >= 0) {
        reversedChildren.push(<ins className="text-success" style={{ textDecorationStyle: 'double' }} title="Addition">{newPrepared.originalWords[nextNewWordIdx]}</ins>);
        --nextNewWordIdx;
    }
    while (nextOldWordIdx >= 0) {
        reversedChildren.push(<del className="text-danger" style={{ opacity: 0.5 }} title="Deletion">{oldPrepared.originalWords[nextOldWordIdx]}</del>);
        --nextOldWordIdx;
    }
    reversedChildren.reverse();

    return createElement(Fragment, {}, ...reversedChildren);
}
export const DiffView = memo(DiffViewImpl);

import { useMutation, useQuery } from '@apollo/client';
import type { ReactElement } from 'react';
import { useCallback } from 'react';
import { Alert, Placeholder } from 'react-bootstrap';
import { AddFriendIcon } from '../assets/index.js';
import { ModalFormRoute, SubmitResultAction } from '../components/ModalFormRoute.js';
import { acceptGroupInvite } from '../mutations/acceptGroupInvite.js';
import { getMyGroups } from '../queries/getMyGroups.js';
import { lookupGroupInvite } from '../queries/lookupGroupInvite.js';

export function JoinGroupModal({ inviteId }: { inviteId: string }): ReactElement {

    const inviteResult = useQuery(lookupGroupInvite,  { variables: { inviteId } });
    const [doAcceptGroupInvite] = useMutation(acceptGroupInvite, {
        refetchQueries: [getMyGroups],
    });

    const invite = inviteResult.data?.previewGroupInvite;

    const handleAttemptSubmit = useCallback(async (
        setError: (error: string | null) => void,
    ): Promise<SubmitResultAction> => {
        if (!inviteId) return SubmitResultAction.STAY_OPEN;

        try {
            await doAcceptGroupInvite({ variables: { inviteId } });
        } catch(e) {
            setError('An error occurred. Please try again');
        }

        return SubmitResultAction.CLOSE;
    }, [doAcceptGroupInvite, inviteId]);

    return (
        <ModalFormRoute
            actions={[
                ...(invite ? [{
                    icon: <AddFriendIcon />,
                    label: invite ? 'Join' : null,
                    onClick: handleAttemptSubmit,
                }] : []),
            ]}
            title="Join Group"
        >
            {inviteResult.loading && <>
                <Placeholder animation="glow" as="p" className="w-75">
                    <Placeholder xs={12} />
                </Placeholder>
                <Placeholder animation="glow" as="p" className="fs-5 text-center">
                    <Placeholder xs={6} />
                </Placeholder>
                <Placeholder animation="glow" as="p" className="w-25">
                    <Placeholder xs={12} />
                </Placeholder>
            </>}
            {invite && <>
                <p><b>{invite.inviterGivenName} {invite.inviterSurname}</b> has invited you to join their group:</p>
                <p className="fs-5 text-center">{invite.groupName}</p>
                <p>Would you like to join?</p>
            </>}
            {(inviteResult.data && inviteResult.data.previewGroupInvite === null) && <>
                <h3>Uh oh!</h3>
                <p>This invite link doesn't look like it was meant for you.</p>
                <p>If a friend sent you this link, verify that they used the same email address that you used to log in.</p>
                <p>If you created this invite link yourself, send it to your friend.</p>
            </>}
            {inviteResult.error && <>
                <Alert variant="danger">
                    <p>An error occurred loading this invite.</p>
                    <p>Please refresh the page to try again.</p>
                </Alert>
            </>}
        </ModalFormRoute>
    );
}

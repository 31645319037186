import { gql } from '../__generated__/index.js';

//language=graphql
export const claimFriendWish = gql(`
    mutation claimFriendWish($wishId: ID!) {
        claimWish(wishId: $wishId) {
            id
            wishId
            recipientId
            title
            comments
            createdAt
            modifiedAt
            originalWish {
                wishId
                createdAt
                modifiedAt
                title
                description
                url
                image
            }
        }
    }
`);

import { useMutation, useQuery } from '@apollo/client';
import { useFlag } from '@karlrwjohnson/libkarl/esm/hooks/useFlag.js';
import { useUnmountedFlag } from '@karlrwjohnson/libkarl/esm/hooks/useUnmountedFlag.js';
import { autofocus } from '@karlrwjohnson/libkarl/esm/react/autofocus.js';
import type { DefaultPreventable } from '@karlrwjohnson/libkarl/esm/react/DefaultPreventable.js';
import type { Dispatch, ReactElement, SetStateAction } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Form, FormControl, InputGroup, Modal, Spinner } from 'react-bootstrap';
import { AddFriendIcon, CopyIcon, LinkIcon } from '../assets/index.js';
import { useStickyModalFooterScrollDetector } from '../hooks/useStickyModalFooterScrollDetector.js';
import type { GroupInvite } from '../mutations/createGroupInvite.js';
import { createGroupInvite } from '../mutations/createGroupInvite.js';
import { getGroupSummary } from '../queries/getGroupSummary.js';
import { buildGroupInviteLink } from '../utils/createGroupInviteLink.js';
import { useErrorState } from '../utils/useErrorState.js';

const EMAIL_REGEX = /^.+@.+\..+$/;

async function getValueFromSetState<T>(setState: Dispatch<SetStateAction<T>>): Promise<T> {
    return await new Promise<T>(res => setState(prev => { res(prev); return prev }));
}

export function InviteModal(
    { groupId }: { groupId: string }
): ReactElement {
    const unmountedFlag = useUnmountedFlag();

    const groupSummary = useQuery(getGroupSummary, { variables: { groupId } });
    const [doCreateGroupInvite, { loading: creatingInvite }] = useMutation(createGroupInvite);

    const submitButtonIcon = <LinkIcon />;
    const submitButtonText = 'Generate Invite Link';
    const title = 'Invite a Friend';

    const { value: isModalOpen, setTrue: openModal, setFalse: closeModal } = useFlag(false);
    const { errorElement, setError } = useErrorState();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<string | null>(null);

    const [invite, setInvite] = useState<GroupInvite>();
    const inviteUrl = useMemo(() => invite ? buildGroupInviteLink(invite) : null, [invite]);
    const urlInputRef = useRef<HTMLInputElement | null>(null);
    const { value: copied, setTrue: setCopied, setFalse: setNotCopied } = useFlag(false);
    const handleClickCopy = useCallback(() => {
        const urlInput = urlInputRef.current;
        if (!urlInput) return;
        urlInput.select();
        document.execCommand('copy');
        setCopied()
    }, [setCopied]);

    const handleSubmit = useCallback(async (evt: DefaultPreventable) => {
        try {
            evt.preventDefault();

            const email = await getValueFromSetState(setEmail);
            if (!email.match(EMAIL_REGEX)) {
                setEmailError('Must be a valid email address');
            }

            const invite = await doCreateGroupInvite({ variables: { groupId, email } });
            if (invite.data?.createGroupInvite) {
                setInvite(invite.data.createGroupInvite);
                closeModal();
                setEmail('');
                setNotCopied();
            } else {
                console.error('Unexpected error', invite.errors);
                setError('Sorry, an error occurred');
            }
        } catch (e) {
            console.error('Unexpected error', e);
            setError('Sorry, an error occurred');
        }
    }, [doCreateGroupInvite, groupId, closeModal, setNotCopied, setError]);

    const { isStickied, setFooterElement } = useStickyModalFooterScrollDetector();

    return (
        <>
            <Button
                className="d-flex flex-row align-items-center gap-2"
                onClick={openModal}
                variant="link"
            >
                <AddFriendIcon />
                <span className="d-none d-md-inline">
                    Invite a Friend
                </span>
            </Button>
            <Modal
                onHide={closeModal}
                show={isModalOpen}
            >
                <form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {errorElement}

                        <p>Invite a friend to the <b>{groupSummary.data?.group?.groupName}</b> group by adding their email address below.</p>

                        <p className="text-muted">(They will need to log in using this email address.)</p>

                        <Form.Group
                            className="my-3"
                            controlId="title"
                        >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                autoFocus
                                onChange={(evt): void => { setEmail(evt.currentTarget.value); setEmailError(null); }}
                                placeholder="email@domain.com"
                                ref={autofocus}
                                size="lg"
                                value={email}
                            />
                            {emailError && <Form.Text className="text-danger">{emailError}</Form.Text>}
                        </Form.Group>
                    </Modal.Body>

                    <Modal.Footer
                        className="position-sticky bottom-0 bg-white"
                        ref={setFooterElement}
                        style={{
                            boxShadow: isStickied ? '0 -0.25rem 0.5rem -0.25rem rgba(0,0,0,0.4)' : '',
                            transition: 'box-shadow 200ms',
                        }}
                    >

                        <div className="flex-grow-1 flex-shrink-1" />

                        <Button
                            className="d-flex flex-row gap-2 align-items-center"
                            disabled={creatingInvite}
                            type="submit"
                            variant="success"
                        >
                            {creatingInvite ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> :
                                submitButtonIcon
                            }
                            {submitButtonText}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Modal
                onHide={(): void => setInvite(undefined)}
                show={!!invite}
            >
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Next, send the link below to your friend:</p>

                    {inviteUrl &&
                    <Form.Group>
                        <InputGroup>
                            <FormControl
                                readOnly
                                ref={urlInputRef}
                                value={inviteUrl}
                            />
                            <Button
                                onClick={handleClickCopy}
                                ref={autofocus}
                            >
                                <CopyIcon /> Copy
                            </Button>
                        </InputGroup>
                        {copied && <Form.Text className="text-success text-end">Copied!</Form.Text>}
                    </Form.Group>
                    }
                </Modal.Body>

                <Modal.Footer
                    className="position-sticky bottom-0 bg-white"
                    ref={setFooterElement}
                    style={{
                        boxShadow: isStickied ? '0 -0.25rem 0.5rem -0.25rem rgba(0,0,0,0.4)' : '',
                        transition: 'box-shadow 200ms',
                    }}
                >
                    <div className="flex-grow-1 flex-shrink-1" />

                    <Button
                        className="d-flex flex-row gap-2 align-items-center"
                        disabled={creatingInvite}
                        onClick={(): void => setInvite(undefined)}
                        variant="outline-secondary"
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

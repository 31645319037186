export function roundToDigits(
    value: number,
    decimalDigitPrecision: number,
): number {
    if (value === 0) {
        return 0;
    }
    const [sign, absValue] = value < 0 ? [-1, -value] : [1, value];

    const powerOf10 = Math.log10(absValue);
    const numberOfDigits = 1 + Math.floor(powerOf10);

    // At 2 digits of precision, 9_951 rounds up to 10_000 because it's greater than 9950 (=10_000 - 100/2)
    const roundUpCutoff = 10 ** numberOfDigits - 0.5 * 10 ** (numberOfDigits - decimalDigitPrecision);
    if (absValue > roundUpCutoff) {
        return 10 ** numberOfDigits;
    }

    const rounded = Math.round(absValue * 10 ** (decimalDigitPrecision - numberOfDigits)) * 10 ** (numberOfDigits - decimalDigitPrecision);
    return sign * rounded;
}

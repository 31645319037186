import { type ReactElement, useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';

export type SetError = (error: string | null) => void;

interface UseErrorState {
    setError: SetError;
    errorElement: ReactElement | null;
}

export function useErrorState() : UseErrorState {
    const [error, setError] = useState(null as string | null);

    const errorElement = useMemo(() => error ? (
        <Alert
            className="my-3"
            dismissible
            onClose={(): void => setError(null)}
            variant="danger"
        >
            {error}
        </Alert>
    ) : null, [error]);

    return { errorElement, setError }
}

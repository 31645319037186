import { type ReactNode, useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import type { OverlayTriggerType } from 'react-bootstrap/OverlayTrigger';
import { roundToDigits } from '../utils/roundToDigits.js';

const LONG_DATE_TIME_FORMAT = new Intl.DateTimeFormat(undefined, { dateStyle: 'long', timeStyle: 'long' });
const DEFAULT_RELATIVE_TIME_FORMAT = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' });

const TRIGGER = ['click', 'hover', 'focus'] satisfies OverlayTriggerType[];

export function RelativeTimeFormat(
    {
        decimalDigitPrecision,
        iso8601Timestamp,
    }: {
        decimalDigitPrecision?: number;
        iso8601Timestamp: string;
    }
): ReactNode {
    const now = Date.now();
    const date = useMemo(() => new Date(iso8601Timestamp), [iso8601Timestamp]);
    const intervalMs = +date - now;
    const relativeTimeStr = formatRelativeTime({ decimalDigitPrecision, intervalMs });
    const dateStr = LONG_DATE_TIME_FORMAT.format(date);

    return (
        <OverlayTrigger
            overlay={<Tooltip>{dateStr}</Tooltip>}
            rootClose
            trigger={TRIGGER}
        >
            <span style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}>{relativeTimeStr}</span>
        </OverlayTrigger>
    );
}

function chooseUnit(
    intervalMs: number
): { unit: Intl.RelativeTimeFormatUnit, millisPerUnit: number } {
    intervalMs = Math.abs(intervalMs);
    return intervalMs < DISPLAY_SECONDS_UNTIL ? { unit: 'second', millisPerUnit: MILLIS_PER_SECOND }
        : intervalMs < DISPLAY_MINUTES_UNTIL ? { unit: 'minute', millisPerUnit: MILLIS_PER_MINUTE }
        : intervalMs < DISPLAY_HOURS_UNTIL ? { unit: 'hour', millisPerUnit: MILLIS_PER_HOUR }
        : intervalMs < DISPLAY_DAYS_UNTIL ? { unit: 'day', millisPerUnit: MILLIS_PER_DAY }
        : intervalMs < DISPLAY_WEEKS_UNTIL ? { unit: 'week', millisPerUnit: MILLIS_PER_WEEK }
        : intervalMs < DISPLAY_MONTHS_UNTIL ? { unit: 'month', millisPerUnit: MILLIS_PER_MONTH_APPROX }
        : { unit: 'year', millisPerUnit: MILLIS_PER_YEAR };
}

const MILLIS_PER_SECOND = 1_000;
const MILLIS_PER_MINUTE = 60 * MILLIS_PER_SECOND;
const MILLIS_PER_HOUR = 60 * MILLIS_PER_MINUTE;
const MILLIS_PER_DAY = 24 * MILLIS_PER_HOUR;
const MILLIS_PER_WEEK = 7 * MILLIS_PER_DAY;
const MILLIS_PER_YEAR = (365 + (1/4) - (1/100) + (1/1000)) * MILLIS_PER_DAY;
const MILLIS_PER_MONTH_APPROX = 1/12 * MILLIS_PER_YEAR;

// These numbers were chosen arbitrarily
const DISPLAY_SECONDS_UNTIL = 2 * MILLIS_PER_MINUTE;
const DISPLAY_MINUTES_UNTIL = 1.5 * MILLIS_PER_HOUR;
const DISPLAY_HOURS_UNTIL = 2 * MILLIS_PER_DAY;
const DISPLAY_DAYS_UNTIL = 2 * MILLIS_PER_WEEK;
const DISPLAY_WEEKS_UNTIL = 10 * MILLIS_PER_WEEK;
const DISPLAY_MONTHS_UNTIL = 800 * MILLIS_PER_DAY;

export function formatRelativeTime(
    {
        decimalDigitPrecision = 2,
        intervalMs,
    }: {
        decimalDigitPrecision?: number;
        intervalMs: number;
    }
): string {
    const { unit, millisPerUnit } = chooseUnit(intervalMs);
    const intervalInUnits = intervalMs / millisPerUnit;
    const roundedIntervalInUnits = roundToDigits(intervalInUnits, decimalDigitPrecision);
    return DEFAULT_RELATIVE_TIME_FORMAT.format(roundedIntervalInUnits, unit);
}

import { gql } from '../__generated__/index.js';

//language=graphql
export const getMyGroups = gql(`
    query getMyGroups {
        groups {
            id
            groupName
        }
    }
`);

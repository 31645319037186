import { raise } from '@karlrwjohnson/libkarl/esm/raise.js';
import 'bootstrap/dist/css/bootstrap.css';
import { UserManager } from 'oidc-client-ts';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App.js';
import { AppApolloProvider } from './AppApolloProvider.js';
import { AppRoutes } from './AppRoutes.js';
import { AuthProvider } from './AuthProvider.js';
import { OAUTH_CLIENT_ID, OAUTH_ISSUER } from './constants.js';
import { REDIRECT_CALLBACK } from './paths.js';
import './esbuild-livereload.js';


const userManager = new UserManager({
    authority: OAUTH_ISSUER,
    client_id: OAUTH_CLIENT_ID,
    redirect_uri: window.location.origin + REDIRECT_CALLBACK,
});

const container = document.getElementById('root') ?? raise('Missing div#root to render application');

const app = (
    <AuthProvider userManager={userManager}>
        <AppApolloProvider>
            <BrowserRouter>
                <App>
                    <AppRoutes />
                </App>
            </BrowserRouter>
        </AppApolloProvider>
    </AuthProvider>
);
const root = createRoot(container)
root.render(app);

import { useQuery } from '@apollo/client';
import { type ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Container, Dropdown, Form, Nav, Navbar, Spinner } from 'react-bootstrap';
import { useMatch } from 'react-router';
import { generatePath, Link } from 'react-router-dom';
import { CurrentRequestCountContext } from './AppApolloProvider.js';
import { LoginIcon, LogoutIcon, PeopleIcon, PlusIcon, UserIcon, WishlistIcon } from './assets/index.js';
import { siteIcon } from './assets/SiteIcon.js';
import { isLoggedIn, useAuthState } from './AuthProvider.js';
import { IfAuthenticated } from './components/IfAuthenticated.js';
import { RouterNavLink } from './components/RouterNavLink.js';
import { useLoginFunction, useLogoutFunction } from './pages/RedirectCallback.js';
import { CREATE_GROUP, FRIEND_WISHLISTS, HOME, MY_WISHLIST } from './paths.js';
import { getMyGroups } from './queries/getMyGroups.js';

export function MainNav(): ReactElement {
    const refreshing = useContext(CurrentRequestCountContext) > 0;

    const sessionInfo = useAuthState();
    const name = isLoggedIn(sessionInfo) ? `${sessionInfo.profile.given_name} ${sessionInfo.profile.family_name}` : '';

    const handleLogin = useLoginFunction();
    const handleLogout = useLogoutFunction();

    return (
        <Navbar
            bg="dark"
            className="position-sticky top-0"
            // expand="sm"
            style={{ zIndex: 2 }}
            variant="dark"
        >
            <Container>
                <Navbar.Brand as={Link} to={HOME}>
                    {refreshing ? <Spinner as="span" animation="border" size="sm" /> : siteIcon}
                    <IfAuthenticated else={<>&nbsp;Wishing Well</>}>
                        <span className="d-none d-md-inline">
                            &nbsp;Wishing Well
                        </span>
                    </IfAuthenticated>
                </Navbar.Brand>
                <Nav className="me-md-auto flex-grow-1 justify-content-evenly justify-content-md-start" variant="pills">
                    <IfAuthenticated>
                        <AuthenticatedCenterNav />
                    </IfAuthenticated>
                </Nav>
                <Nav>
                    <IfAuthenticated
                        else={
                            <Form className="d-flex"><Button variant="primary" onClick={handleLogin}><LoginIcon /> Sign In</Button></Form>
                        }
                    >
                        <Dropdown>
                            <Dropdown.Toggle className="nav-link" variant="link" id="user-menu-dropdown">
                                <UserIcon /><span className="d-none d-md-inline">&nbsp; {name}</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                                <Dropdown.Item onClick={handleLogout}>
                                    <LogoutIcon /> Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </IfAuthenticated>
                </Nav>
            </Container>
        </Navbar>
    );
}


export function AuthenticatedCenterNav(): ReactElement {

    const { loading, error, data } = useQuery(getMyGroups, {
        initialFetchPolicy: 'cache-and-network',
    });

    const groupId = useMatch(FRIEND_WISHLISTS)?.params.groupId;
    const [lastSelectedGroupId, setLastSelectedGroupId] = useState<string | null>(null);
    useEffect(() => {
        if (groupId) {
            setLastSelectedGroupId(groupId);
        }
    }, [groupId]);
    const lastSelectedGroup = useMemo(() => data?.groups.find(group => group.id === lastSelectedGroupId), [data, lastSelectedGroupId]);

    return (
        <>
            <Nav.Link activeClassName="active" as={RouterNavLink} className="flex-shrink-0" to={MY_WISHLIST}>
                <WishlistIcon />&nbsp;My&nbsp;Wishlist
            </Nav.Link>

            {data?.groups.map(group =>
                <Nav.Link
                    activeClassName="active"
                    as={RouterNavLink}
                    className="d-lg-block d-none"
                    key={group.id}
                    to={generatePath(FRIEND_WISHLISTS, { groupId: group.id })}
                >
                    <PeopleIcon /> {group.groupName}
                </Nav.Link>
            )}
            <Nav.Link
                activeClassName="active"
                as={RouterNavLink}
                className="d-lg-block d-none"
                to={CREATE_GROUP}
            >
                <PlusIcon /> New Group
            </Nav.Link>
            <Dropdown
                as={ButtonGroup}
                className="d-flex flex-row flex-shrink-1 d-lg-none"
                style={{ minWidth: 0 }}
            >
                {lastSelectedGroupId && lastSelectedGroup ?
                    <>
                        <Nav.Link
                            activeClassName="active"
                            as={RouterNavLink}
                            className="btn flex-grow-1 flex-shrink-1"
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                minWidth: 0,
                                textOverflow: 'ellipsis',
                            }}
                            to={generatePath(FRIEND_WISHLISTS, { groupId: lastSelectedGroupId })}
                        >
                            <PeopleIcon /> {lastSelectedGroup.groupName}
                        </Nav.Link>
                        <Dropdown.Toggle className={groupId ? '' : `nav-link`} split variant={groupId ? 'outline-primary' : 'link'} id="groups-dropdown" />
                    </> :
                    <Dropdown.Toggle className={`nav-link ${groupId ? 'active' : ''}`} variant="link" id="groups-dropdown">
                        <PeopleIcon /> Groups
                    </Dropdown.Toggle>
                }
                <Dropdown.Menu>
                    {data?.groups.map(group =>
                        <Dropdown.Item
                            activeClassName="active"
                            as={RouterNavLink}
                            key={group.id}
                            to={generatePath(FRIEND_WISHLISTS, { groupId: group.id })}
                        >
                            {group.groupName}
                        </Dropdown.Item>
                    )}
                    <Dropdown.Divider />
                    <Dropdown.Item
                        activeClassName="active"
                        as={RouterNavLink}
                        to={CREATE_GROUP}
                    >
                        <PlusIcon /> New Group
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

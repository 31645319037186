import { gql } from '../__generated__/index.js';

//language=graphql
export const getMyWish = gql(`
    query getMyWish ($wishId: ID!) {
        getMyWish(wishId: $wishId) {
            id
            createdAt
            modifiedAt
            title
            description
            url
            image
            isDraft
        }
    }
`);

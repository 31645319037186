import { gql } from '../__generated__/index.js';

//language=graphql
export const createGift = gql(`
    mutation createGift($createGift: CreateGiftInput!) {
        createGift(gift: $createGift) {
            id
            wishId
            recipientId
            title
            comments
            createdAt
            modifiedAt
            originalWish {
                id
                createdAt
                modifiedAt
                title
                description
                url
                image
            }
        }
    }
`);

/* eslint-disable @typescript-eslint/no-var-requires */
import { loadSvgAsJsx } from '../utils/loadSvgAsJSX.js';

export const SiteIcon = loadSvgAsJsx(require('./wishing-well-icon.svg') as string);
export const EmptyWell = loadSvgAsJsx(require('./empty-well.svg') as string);
export const DuplicateGifts = loadSvgAsJsx(require('./duplicate-gifts.svg') as string);
export const NoIdeas = loadSvgAsJsx(require('./no-ideas.svg') as string);

export const WishlistIcon = loadSvgAsJsx(require('bootstrap-icons/icons/heart-fill.svg') as string);
// export const WishlistIcon = loadSvgAsJsx(require('bootstrap-icons/icons/suit-heart-fill.svg') as string);
export const DraftWishIcon = loadSvgAsJsx(require('bootstrap-icons/icons/heart-half.svg') as string);
// export const WishlistIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/heart.svg') as string);
// export const CartIcon = loadSvgAsJsx(require('bootstrap-icons/icons/cart-fill.svg') as string);
export const CartIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/shopping-cart.svg') as string);
export const PeopleIcon = loadSvgAsJsx(require('bootstrap-icons/icons/people-fill.svg') as string);
export const PlusIcon = loadSvgAsJsx(require('bootstrap-icons/icons/plus.svg') as string);

export const ClaimWishIcon = loadSvgAsJsx(require('bootstrap-icons/icons/bookmark.svg') as string);
export const ClaimedWishIcon = loadSvgAsJsx(require('bootstrap-icons/icons/bookmark-check-fill.svg') as string);

export const UserIcon = loadSvgAsJsx(require('bootstrap-icons/icons/person-fill.svg') as string);
// export const UserIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/user.svg') as string);

export const AlertIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/exclamation-triangle.svg') as string);
export const CheckCircleOutline = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/regular/check-circle.svg') as string);
export const LoginIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/sign-in-alt.svg') as string);
export const LogoutIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/sign-out-alt.svg') as string);
export const EditIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/edit.svg') as string);
// export const PlusIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/plus.svg') as string);
export const SaveIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/regular/save.svg') as string);
export const ScrollIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/scroll.svg') as string);
export const DeleteIcon = loadSvgAsJsx(require('bootstrap-icons/icons/x-circle.svg') as string);
// export const DeleteIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/trash.svg') as string);
export const SearchIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/search.svg') as string);
// export const SearchIcon = loadSvgAsJsx(require('bootstrap-icons/icons/search.svg') as string);

export const UserCheckIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/user-check.svg') as string);

export const GiftIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/gift.svg') as string);
// export const GiftIcon = loadSvgAsJsxequire('bootstrap-icons/icons/gift-fill.svg') as string);

export const LinkIcon = loadSvgAsJsx(require('bootstrap-icons/icons/link-45deg.svg') as string);
export const AddFriendIcon = loadSvgAsJsx(require('bootstrap-icons/icons/person-plus-fill.svg') as string);
export const CopyIcon = loadSvgAsJsx(require('@fortawesome/fontawesome-free/svgs/solid/copy.svg') as string);
export const LeaveGroupIcon = loadSvgAsJsx(require('bootstrap-icons/icons/box-arrow-left.svg') as string);

import type { User, UserLoadedCallback, UserManager, UserUnloadedCallback } from 'oidc-client-ts';
import type { Dispatch, ReactElement, ReactNode, SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
    createContextWithProviderAssertion,
    useContextWithProviderAssertion,
} from './utils/createContextWithProviderAssertion.js';

export const LOADING_SESSION_FROM_REDIRECT = Symbol('Loading session from redirect');
export const LOADING_SESSION_FROM_STORAGE = Symbol('Loading session from storage');
export const NOT_LOGGED_IN = Symbol('Not logged in');

export type AuthState =
    | User
    | typeof NOT_LOGGED_IN
    | typeof LOADING_SESSION_FROM_STORAGE
    | typeof LOADING_SESSION_FROM_REDIRECT
    ;

export function isLoggedIn(authState: AuthState): authState is User {
    return typeof authState !== 'symbol'
}

type AuthStateContextType = [AuthState, Dispatch<SetStateAction<AuthState>>, UserManager];
export const authStateContext = createContextWithProviderAssertion<AuthStateContextType>('authState');
const { Provider } = authStateContext;

export function useAuthState(): AuthState {
    return useContextWithProviderAssertion(authStateContext)[0];
}

export function useUserManager(): UserManager {
    return useContextWithProviderAssertion(authStateContext)[2];
}

export function AuthProvider(
    {
        children,
        userManager,
    }: {
        children?: ReactNode;
        userManager: UserManager;
    }
): ReactElement {
    const [state, setState] = useState<AuthState>(LOADING_SESSION_FROM_STORAGE)

    useEffect(() => {
        userManager.getUser().then(user =>
            setState(user && !user.expired
                ? user
                : (prev): AuthState =>
                    prev === LOADING_SESSION_FROM_STORAGE
                        ? NOT_LOGGED_IN
                        : prev,
            )
        );

        const userLoaded: UserLoadedCallback = user => setState(user);
        const userUnloaded: UserUnloadedCallback = () => setState(NOT_LOGGED_IN);

        userManager.events.addUserLoaded(userLoaded);
        userManager.events.addUserUnloaded(userUnloaded);

        return () => {
            userManager.events.removeUserLoaded(userLoaded);
            userManager.events.removeUserUnloaded(userUnloaded);
        };
    }, [userManager]);

    const providerValue: AuthStateContextType = useMemo(() => [state, setState, userManager], [state, setState, userManager]);

    return (
        <Provider value={providerValue}>
            {children}
        </Provider>
    )
}

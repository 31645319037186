import { isDefined } from '@karlrwjohnson/libkarl/esm/isDefined.js';
import { useLayoutEffect, useState } from 'react';

export interface UseStickyModalFooterScrollDetector {
    isStickied: boolean;

    setFooterElement(element: HTMLElement | null): void;
}

/**
 * VERY specific hook for detecting whether a modal footer which has been `position:sticky`d to the bottom of a screen
 * is currently stickied
 */
export function useStickyModalFooterScrollDetector(): UseStickyModalFooterScrollDetector {
    const [footerElement, setFooterElement] = useState<HTMLElement | null>(null);
    const [scrolled, setScrolled] = useState(false);
    useLayoutEffect(() => {
        // Abort on null so we aren't calling onScroll() before we have an element
        if (!isDefined(footerElement)) return;
        // Alias the reference after an explicit type check because Typescript
        // may think that the function
        const f = footerElement;

        function handleScroll(): void {
            if (!f.previousElementSibling) return;
            const previousSibling = f.previousElementSibling as HTMLElement;
            const nowScrolled = (f.offsetTop < previousSibling.offsetTop + previousSibling.offsetHeight);
            setScrolled(nowScrolled);
        }

        const scrollParent = document.querySelector('.modal');
        if (!scrollParent) {
            console.warn('Missing scroll parent');
            return;
        }

        // Immediately check the condition
        handleScroll();
        window.addEventListener('resize', handleScroll);
        scrollParent.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('resize', handleScroll);
            scrollParent.removeEventListener('scroll', handleScroll);
        };
    }, [footerElement]);

    return { isStickied: scrolled, setFooterElement };
}

import { gql } from '../__generated__/index.js';

//language=graphql
export const createWish = gql(`
    mutation createWish($createWish: CreateWishInput!) {
        createWish(wish: $createWish) {
            id
            title
            description
            url
            image
            isDraft
            createdAt
        }
    }
`);

import type { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router';
import { LOGIN } from '../paths.js';

const REDIRECT_QUERY_PARAM_KEY = 'redirect';

export function FollowRedirectFromLogin(): ReactElement {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const redirectTo = params.get(REDIRECT_QUERY_PARAM_KEY) ?? '/';
    return <Navigate to={redirectTo} replace />;
}

export function useLoginRedirectUrl(loginPageUrl: string): string {
    const location = useLocation();
    const locationString = location.pathname + location.hash + location.search;
    return `${loginPageUrl}?${REDIRECT_QUERY_PARAM_KEY}=${encodeURIComponent(locationString)}`;
}

export function RedirectToLogin(): ReactElement {
    const loginRedirectUrl = useLoginRedirectUrl(LOGIN);
    return <Navigate to={loginRedirectUrl} replace />;
}

import { gql } from '../__generated__/index.js';

//language=graphql
export const createGroup = gql(`
    mutation createGroup($groupName: String!) {
        createGroup(groupName: $groupName) {
            id
        }
    }
`);

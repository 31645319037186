import { useQuery } from '@apollo/client';
import { comparingBy } from '@karlrwjohnson/libkarl/esm/comparingBy.js';
import { autofocus } from '@karlrwjohnson/libkarl/esm/react/autofocus.js';
import { transformState } from '@karlrwjohnson/libkarl/esm/transformBuilder/index.js';
import type { ChangeEvent, Dispatch, FormEvent, ReactElement, SetStateAction } from 'react';
import { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { getFriendWishes } from '../queries/getFriendWishes.js';
import { FriendOptions } from './FriendOptions.js';

export function FriendWishOptions(
    {
        friendId,
        selectedWishId,
    }: {
        friendId: string;
        selectedWishId: string | null | undefined;
    },
): ReactElement {
    const result = useQuery(getFriendWishes, { variables: { userId: friendId } });
    const friendWishes = result.data?.friend?.wishes
            .slice()
            .sort(comparingBy(friendWish => friendWish.isClaimedBySomeoneElse + '_' + friendWish.title))
        ?? [];

    if (selectedWishId && !friendWishes.some(it => it.id === selectedWishId)) {
        friendWishes.push(
            {
                id: selectedWishId,
                title: (result.loading ? '(loading)' : `Wish ${selectedWishId}`),
                isClaimed: false,
                isClaimedBySomeoneElse: false,
            },
        )
    }

    const options = friendWishes.map(friendWish =>
        <option key={friendWish.id} value={friendWish.id}>{friendWish.title}{friendWish.isClaimedBySomeoneElse ? ' (taken)' : ''}</option>,
    );

    return (
        <>
            <option className="text-muted" value="">(None)</option>
            {options}
        </>
    );
}

export type FormState = {
    title: string;
    comments: string;
    recipientId: string;
    wishId: string;
};

export type ValidationErrors = Record<string, string>;

export function GiftForm(
    {
        formState,
        setFormState,
        setValidationErrors,
        validationErrors,
    }: {
        formState: FormState;
        setFormState: Dispatch<SetStateAction<FormState>>;
        setValidationErrors: Dispatch<SetStateAction<ValidationErrors>>;
        validationErrors: ValidationErrors;
    },
): ReactElement {

    const handleChange = useCallback((evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | FormEvent<HTMLSelectElement>) => {
        const { id: key, value } = evt.currentTarget;
        switch (key) {
            case 'title':
            case 'comments':
            case 'wishId':
                setFormState(prev => ({ ...prev, [key]: value }));
                break;
            case 'recipientId':
                setFormState(prev => ({ ...prev, recipientId: value, wishId: '' }));
                break;

            default:
                return;
        }
        transformState(setValidationErrors).deleteKey(key).and.apply();
    }, [setFormState, setValidationErrors]);

    return (
        <>
            <Form.Group
                className="my-3"
                controlId="title"
            >
                <Form.Label>Title</Form.Label>
                <Form.Control
                    autoFocus
                    onChange={handleChange}
                    placeholder="Title"
                    ref={autofocus}
                    size="lg"
                    value={formState.title}
                />
                {validationErrors.title &&
                    <Form.Text className="text-danger">{validationErrors.title}</Form.Text>
                }
            </Form.Group>

            <Form.Group
                className="my-3"
                controlId="recipientId"
            >
                <Form.Label>Recipient</Form.Label>
                <Form.Select
                    onChange={handleChange}
                    placeholder="Additional details about this item"
                    value={formState.recipientId ?? ''}
                >
                    <FriendOptions selectedUserId={formState.recipientId}/>
                </Form.Select>
                {validationErrors.recipientId &&
                    <Form.Text className="text-danger">{validationErrors.recipientId}</Form.Text>
                }
            </Form.Group>

            <Form.Group
                className="my-3"
                controlId="wishId"
            >
                <Form.Label>Wish</Form.Label>
                <Form.Select
                    disabled={!formState.recipientId}
                    onChange={handleChange}
                    placeholder="Additional details about this item"
                    value={formState.wishId ?? ''}
                >
                    {formState.recipientId ?
                        <FriendWishOptions
                            friendId={formState.recipientId ?? ''}
                            selectedWishId={formState.wishId}
                        /> :
                        <option value="">Select recipient to choose a wish</option>
                    }
                </Form.Select>
                {validationErrors.wishId &&
                    <Form.Text className="text-danger">{validationErrors.wishId}</Form.Text>
                }
            </Form.Group>

            <Form.Group
                className="my-3"
                controlId="comments"
            >
                <Form.Label>Notes</Form.Label>
                <Form.Control
                    as="textarea"
                    onChange={handleChange}
                    placeholder="Additional details about this item"
                    value={formState.comments}
                />
                {validationErrors.comments &&
                    <Form.Text className="text-danger">{validationErrors.comments}</Form.Text>
                }
            </Form.Group>
        </>
    );
}

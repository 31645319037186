import { generatePath } from 'react-router-dom';
import { JOIN_GROUP } from '../paths.js';
import type { GroupInvite } from '../mutations/createGroupInvite.js';

/**
 * Build a link for a user to add themselves to a group
 */
export function buildGroupInviteLink(invite: GroupInvite): string {
    return window.location.origin + generatePath(JOIN_GROUP, { inviteId: invite.id });
}

/**
 * Build a mailto: protocol link
 * @param invite - server-generated invitation object
 * @param url - result of createGroupInviteLink()
 */
export function buildGroupInviteMailtoLink(invite: GroupInvite, url: string): string {
    const params = new URLSearchParams();
    params.append('subject', `Wishing Well: You've been invited to the ${invite.groupName} group!`);
    params.append('body', 'Join the group using the link below:\n\n' + url);
    return 'mailto:' + encodeURIComponent(invite.inviteeEmail) + '?' + params.toString()
}

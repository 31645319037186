import { ApolloLink, Observable } from '@apollo/client';
import { Readiable } from './Readiable.js';

/**
 * Wrap a "readiable" Apollo link with a plain apollo link
 * that waits for the wrapper to reach its "ready" state before doing its thing.
 */
export function deferRequestUntilWrappedLinkReady<T extends ApolloLink>(readiable: Readiable<T>): ApolloLink {
    return new ApolloLink((operation, forward) =>
        new Observable(observer => {
            let unsubscribed = false;

            readiable.nextReady()
                .then(linkImpl => {
                    if (unsubscribed) {
                        return;
                    }

                    if (linkImpl === Readiable.DESTROYED) {
                        observer.complete();
                        return;
                    }

                    linkImpl.request(operation, forward)?.subscribe(observer);
                })
                .catch(error => {
                    observer.error(error);
                });

            // Unsubscribe
            return () => {
                unsubscribed = true;
                observer.complete();
            };
        }),
    );
}

import { gql } from '../__generated__/index.js';
import type { ResultTypeOfQuery } from '../utils/ResultTypeOfQuery.js';

//language=graphql
export const createGroupInvite = gql(`
    mutation createGroupInvite($groupId: ID!, $email: String!) {
        createGroupInvite(groupId: $groupId, email: $email) {
            id
            groupId
            groupName
            inviterId
            inviterGivenName
            inviterSurname
            inviteeEmail
        }
    }
`);

export type GroupInvite = NonNullable<ResultTypeOfQuery<typeof createGroupInvite>['createGroupInvite']>;

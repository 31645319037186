import { gql } from '../__generated__/index.js';

//language=graphql
export const saveEditGift = gql(`
    mutation saveEditGift($giftId: ID!, $gift: EditGiftInput!) {
        editGift(giftId: $giftId, gift: $gift) {
            id
            wishId
            recipientId
            title
            comments
            createdAt
            modifiedAt
            originalWish {
                wishId
                createdAt
                modifiedAt
                title
                description
                url
                image
            }
        }
    }
`);

import { gql } from '../__generated__/index.js';
import type { ResultTypeOfQuery } from '../utils/ResultTypeOfQuery.js';

//language=graphql
export const getMyGift = gql(`
    query getMyGift($giftId: ID!) {
        myGift(giftId: $giftId) {
            id
            wishId
            recipientId
            title
            comments
            createdAt
            modifiedAt
            originalWish {
                id
                createdAt
                modifiedAt
                title
                description
                url
                image
            }
            recipient {
                id
                givenName
                surname
            }
        }
    }
`);

export type MyGift = NonNullable<ResultTypeOfQuery<typeof getMyGift>['myGift']>;

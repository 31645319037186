function getOperationName(init: RequestInit): string | null {
    const bodyParameter = init.body;
    if (typeof bodyParameter !== 'string') return null;
    let parsedBody: unknown;
    try {
        parsedBody = JSON.parse(bodyParameter);
    } catch (e) {
        return null;
    }
    if (typeof parsedBody !== 'object' || parsedBody === null) return null;
    const operationName = (parsedBody as Record<string, unknown>)['operationName'];
    if (typeof operationName !== 'string') return null;
    return operationName;
}

export const fetchAndAlsoAttachOperationNameToUrl: typeof fetch = async (input, init) => {
    const operationName = init && getOperationName(init);

    if (operationName) {
        let url: URL;
        if (input instanceof URL) {
            url = new URL(input);
        } else if (typeof input === 'string') {
            url = new URL(input);
        } else {
            url = new URL((input as Request).url);
        }

        // Mutate the request's search params to add the new parameter
        // I didn't know you could do this!
        url.searchParams.append('operationName', operationName);

        if (input instanceof URL) {
            input = url as URL;
        } else if (typeof input === 'string') {
            input = url.toString();
        } else {
            throw new Error('Unsupported: `input` parameter is a Request object')
        }
    }

    // undici-types/fetch.d.ts has infected Typescript's idea of what the `fetch()` function is supposed to be,
    // causing the type of `input` to throw a senseless type error. (There's two copies of `URL` and `Request`
    // floating around!). Casting to `any` for now because there's too much other stuff to deal with.
    return await fetch(input as any, init);
};

import { useMutation, useQuery } from '@apollo/client';
import type { ReactElement, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { SaveIcon } from '../assets/index.js';
import type { FormState } from '../components/GiftForm.js';
import { GiftForm } from '../components/GiftForm.js';
import { ModalFormRoute, SubmitResultAction } from '../components/ModalFormRoute.js';
import { deleteGift } from '../mutations/deleteGift.js';
import { getGroupDetails } from '../queries/getGroupDetails.js';
import { getMyGift } from '../queries/getMyGift.js';
import { getMyGifts } from '../queries/getMyGifts.js';
import { saveEditGift } from '../mutations/saveEditGift.js';

export function EditGiftModal({ giftId }: { giftId: string }): ReactElement {
    const [doDeleteGift] = useMutation(deleteGift);
    const [doEditGift] = useMutation(saveEditGift);
    const originalGiftResult = useQuery(getMyGift, { variables: { giftId } });
    const originalGift = originalGiftResult.data?.myGift;

    const [formState, setFormState] = useState<FormState>({
        title: '',
        comments: '',
        recipientId: '',
        wishId: '',
    });
    const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

    useEffect(() => {
        if (originalGift) {
            setFormState({
                title: originalGift.title,
                comments: originalGift.comments || '',
                recipientId: originalGift.recipientId || '',
                wishId: originalGift.originalWish?.id || '',
            });
        }
    }, [originalGift]);

    const validateAndReturnValid = useCallback((): boolean => {
        const validationErrors: Record<string, string> = {};
        if (!formState.title) {
            validationErrors.title = 'This field is required';
        }
        setValidationErrors(validationErrors);

        return Object.entries(validationErrors).length === 0;
    }, [formState.title]);

    const handleAttemptSubmit = useCallback(async (
        setError: (error: string | null) => void
    ): Promise<SubmitResultAction> => {
        const isValid = validateAndReturnValid();
        if (!isValid) {
            setError('Fix the errors below and try again');
            return SubmitResultAction.STAY_OPEN;
        }

        const result = await doEditGift({
            refetchQueries: [getMyGifts, getGroupDetails],
            variables: {
                giftId,
                gift: {
                    title: formState.title,
                    comments: formState.comments || null,
                    recipientId: formState.recipientId || null,
                    wishId: formState.wishId || null,
                },
            },
        });

        if (result.errors && result.errors.length > 0) {
            const messages = result.errors.map(e => e.message).join('\n');
            setError('Error editing gift:\n' + messages);
            return SubmitResultAction.STAY_OPEN;
        } else if (!result.data) {
            setError('Unknown error editing gift')
            return SubmitResultAction.STAY_OPEN;
        }

        return SubmitResultAction.CLOSE;
    }, [doEditGift, formState.comments, formState.recipientId, formState.title, formState.wishId, giftId, validateAndReturnValid]);

    const handleDelete = useCallback(async () => {
        await doDeleteGift({
            refetchQueries: [getMyGifts, getGroupDetails],
            variables: {
                giftId,
            }
        });

        return SubmitResultAction.CLOSE;
    }, [doDeleteGift, giftId]);

    let form: ReactNode;
    if (originalGiftResult.loading) {
        form = <p className="mt-5 text-center"><Spinner animation="border" /></p>;
    } else if (originalGiftResult.error) {
        form = (
            <Alert variant="danger">
                <p>Errors loading gift: {originalGiftResult.error.message}</p>
            </Alert>
        );
    } else {
        form = (
            <GiftForm
                formState={formState}
                setFormState={setFormState}
                setValidationErrors={setValidationErrors}
                validationErrors={validationErrors}
            />
        );
    }

    return (
        <ModalFormRoute
            actions={[
                {
                    confirm: {
                        cancelLabel: 'No, keep',
                        confirmLabel: 'Yes, delete',
                        confirmVariant: 'danger',
                        messageBody: 'Are you sure you want to do this?',
                        messageTitle: 'Delete Gift',
                    },
                    label: 'Delete Gift',
                    onClick: handleDelete,
                    variant: 'outline-danger',
                },
                {
                    icon: <SaveIcon />,
                    label: 'Update',
                    onClick: handleAttemptSubmit,
                },
            ]}
            title="Edit Gift"
        >
            {form}
        </ModalFormRoute>
    );
}

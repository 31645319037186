import { useQuery } from '@apollo/client';
import type { ReactElement } from 'react';
import { Alert, Badge, Card, Image, ListGroup, Spinner } from 'react-bootstrap';
import { Outlet } from 'react-router';
import { generatePath, Link } from 'react-router-dom';
import { EmptyWell, PlusIcon, SearchIcon } from '../assets/index.js';
import { DiffView } from '../components/DiffView.js';
import { CREATE_GIFT, EDIT_GIFT, FRIEND_WISHLISTS } from '../paths.js';
import type { MyGift } from '../queries/getMyGifts.js';
import { getMyGifts } from '../queries/getMyGifts.js';

export function MyGiftView({ myGift: gift }: { myGift: MyGift }): ReactElement {
    const recipient = gift.recipient;
    const originalWish = gift.originalWish;
    const currentWish = gift.currentWish;

    const wasDeleted = originalWish && !currentWish;
    const wasModified = currentWish && originalWish && (
        currentWish.title !== originalWish.title ||
        currentWish.description !== originalWish.description ||
        currentWish.url !== originalWish.url ||
        currentWish.image !== originalWish.image
    );

    return (
        <ListGroup.Item
            action
            as={Link}
            to={generatePath(EDIT_GIFT, { giftId: gift.id })}
        >
            <Card.Title>
                {gift?.title}
            </Card.Title>
            <p><span className="text-muted">for</span> {recipient ? recipient.givenName + ' ' + recipient.surname : <i>TBD</i>}</p>

            {gift.comments && <p style={{ whiteSpace: 'pre-wrap' }}>{gift.comments}</p>}

            {(currentWish || originalWish) &&
            <Card>
                <Card.Body>
                    {wasDeleted ? <Badge bg="danger" className="mb-3">Deleted</Badge> : undefined}
                    {wasModified ? <Badge bg="success" className="mb-3">Updated</Badge> : undefined}
                    <Card.Title>
                        <DiffView oldText={originalWish?.title ?? ''} newText={currentWish?.title ?? ''} />
                    </Card.Title>
                    {currentWish?.description || originalWish?.description ?
                        <p style={{ whiteSpace: 'pre-wrap' }}><DiffView oldText={originalWish?.description ?? ''} newText={currentWish?.description ?? ''} /></p> :
                        undefined}
                    {currentWish?.url && <a href={currentWish.url}>{currentWish.url}</a>}
                    {currentWish?.image && <Image alt="user-provided image" fluid src={currentWish.image} />}
                </Card.Body>
            </Card>
            }
        </ListGroup.Item>
    );
}

export function ShoppingList(): ReactElement {

    const { data, error, loading } = useQuery(getMyGifts);


    if (loading) {
        return <p className="mt-5 text-center"><Spinner animation="border" /></p>
    }
    if (!data) {
        if (error) {
            return (
                <Alert variant="danger">
                    <p>Errors loading shopping list: {error.message}</p>
                    {error.clientErrors.map((e, i) => <p key={i}>{e.message}</p>)}
                </Alert>
            );
        }
        return (
            <Alert variant="danger">
                Shopping list did not load
            </Alert>
        );
    }

    const myGifts = data.myGifts;

    return (
        <>
            <h1>Shopping List</h1>
            <hr />

            {myGifts.length > 0 ?
                <ListGroup className="mt-3 mb-5 shadow">
                    {myGifts.map(gift => <MyGiftView key={gift.id} myGift={gift} />)}
                </ListGroup>:
                <div className="text-center my-4">
                    <h3 className="mb-3">{`You haven't claimed any gifts yet`}</h3>
                    <p>
                        Find gift ideas among your friends' wishlists,<br />
                        or add your own ideas here to keep track of them in one place
                    </p>
                </div>
            }

            <div className="d-flex flex-row justify-content-center gap-3 mb-5 mt-3 mx-auto w-100 position-sticky">
                <Link
                    className="btn btn-primary btn-lg d-block"
                    style={{
                        bottom: '0.5rem',
                        boxShadow: '0 0 1rem rgba(0,0,0,0.4)',
                        maxWidth: '200px',
                        zIndex: 1,
                    }}
                    to={FRIEND_WISHLISTS}
                >
                    <SearchIcon /> Find a gift
                </Link>
                <Link
                    className="btn btn-success btn-lg d-block"
                    style={{
                        bottom: '0.5rem',
                        boxShadow: '0 0 1rem rgba(0,0,0,0.4)',
                        maxWidth: '400px',
                        zIndex: 1,
                    }}
                    to={CREATE_GIFT}
                >
                    <PlusIcon /> Track your own gift idea
                </Link>
            </div>

            {!(myGifts.length > 0) &&
            <EmptyWell
                className="my-5 mx-auto d-block"
                style={{
                    width: 229,
                    height: 342,
                }}
            /> }

            <Outlet />
        </>
    );
}

import { PUBLIC_PATH } from './constants.js';

export const HOME = `${PUBLIC_PATH}/` as const;
export const LOGIN = `${PUBLIC_PATH}/login` as const;
export const REDIRECT_CALLBACK = `${PUBLIC_PATH}/redirect`;
export const MY_WISHLIST = `${PUBLIC_PATH}/my-wishlist` as const;
export const GROUPS_ROOT = `${PUBLIC_PATH}/groups` as const;
export const CREATE_GROUP = `${GROUPS_ROOT}/create` as const;
export const FRIEND_WISHLISTS = `${GROUPS_ROOT}/:groupId` as const;
export const SHOPPING_LIST = `${PUBLIC_PATH}/shopping-list` as const;

export const CREATE_WISH = `${MY_WISHLIST}/create` as const;
export const EDIT_WISH = `${MY_WISHLIST}/edit/:wishId` as const;

export const CREATE_GIFT = `${SHOPPING_LIST}/create` as const;
export const EDIT_GIFT = `${SHOPPING_LIST}/edit/:giftId` as const;
export const FRIEND_WISHLIST_CREATE_GIFT = `${FRIEND_WISHLISTS}/create` as const;
export const FRIEND_WISHLIST_EDIT_GIFT = `${FRIEND_WISHLISTS}/edit/:giftId` as const;

export const JOIN_GROUP = `${PUBLIC_PATH}/join-group/:inviteId` as const;

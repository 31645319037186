import { useFlag } from '@karlrwjohnson/libkarl/esm/hooks/useFlag.js';
import { useUnmountedFlag } from '@karlrwjohnson/libkarl/esm/hooks/useUnmountedFlag.js';
import type { DefaultPreventable } from '@karlrwjohnson/libkarl/esm/react/DefaultPreventable.js';
import { preventDefault } from '@karlrwjohnson/libkarl/esm/react/DefaultPreventable.js';
import type { ReactElement, ReactNode } from 'react';
import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { Button, Overlay, Popover, Spinner } from 'react-bootstrap';
import type { ButtonVariant, Placement } from 'react-bootstrap/types';
import { DeleteIcon } from '../assets/index.js';

export function DeleteButtonWithConfirm(
    {
        buttonClassName = '',
        buttonDisabled = false,
        buttonIcon = <DeleteIcon />,
        buttonLabel = 'Delete',
        buttonVariant = 'outline-danger',
        cancelButtonLabel = 'No, Keep',
        confirmButtonLabel = 'Yes, Delete',
        confirmButtonIcon = <DeleteIcon />,
        confirmButtonVariant = 'danger',
        confirmMessageTitle,
        confirmMessageBody = 'Are you sure you want to do this?',
        onConfirm,
        placement,
    }: {
        buttonClassName?: string;
        buttonDisabled?: boolean;
        buttonIcon?: ReactNode;
        buttonLabel?: ReactNode;
        buttonVariant?: ButtonVariant;
        cancelButtonLabel?: ReactNode;
        confirmButtonLabel?: ReactNode;
        confirmButtonIcon?: ReactNode;
        confirmButtonVariant?: ButtonVariant;
        confirmMessageTitle?: string;
        confirmMessageBody?: ReactNode;
        onConfirm(): Promise<void> | void;
        placement?: Placement;
    }
): ReactElement {
    const showing = useFlag(false);
    const [loading, setLoading] = useState(false);
    const targetRef = useRef<HTMLButtonElement>(null);
    const unmounted = useUnmountedFlag();

    const handleClickConfirm = useCallback(async (evt: DefaultPreventable) => {
        evt.preventDefault();

        setLoading(true);
        try {
            await onConfirm();
        } finally {
            if (unmounted.current) {
                setLoading(false);
            }
        }
    }, [onConfirm, unmounted]);

    return (
        <>
            <Button
                className={`d-flex flex-row align-items-center gap-2 ${buttonClassName}`}
                disabled={buttonDisabled}
                onClick={showing.setTrue}
                ref={targetRef}
                variant={buttonVariant}
            >
                {buttonIcon}{buttonLabel}
            </Button>
            <Overlay
                onHide={showing.setFalse}
                placement={placement}
                show={showing.value}
                rootClose
                target={targetRef}
            >
                <Popover
                    id="confirm-popover"
                    className="shadow"
                    onClick={preventDefault}
                >
                    {confirmMessageTitle && <Popover.Header>{confirmMessageTitle}</Popover.Header>}
                    <Popover.Body>
                        <p>{confirmMessageBody}</p>
                        <div className="d-flex flex-row justify-content-end gap-2">
                            <Button
                                disabled={loading}
                                onClick={showing.setFalse}
                                size="sm"
                                variant="outline-secondary"
                            >
                                {cancelButtonLabel}
                            </Button>
                            <Button
                                disabled={loading}
                                onClick={handleClickConfirm}
                                size="sm"
                                variant={confirmButtonVariant}
                            >
                                {loading ?
                                    <Spinner animation="border" size="sm" /> :
                                    confirmButtonIcon
                                }
                                &nbsp;
                                {confirmButtonLabel}
                            </Button>
                        </div>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </>
    );
}

/* eslint-disable */
import * as types from './graphql.js';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    mutation acceptGroupInvite($inviteId: ID!) {\n        acceptGroupInvite(inviteId: $inviteId) {\n            id\n            groupId\n            groupName\n            inviterId\n            inviterGivenName\n            inviterSurname\n            inviteeEmail\n        }\n    }\n": types.AcceptGroupInviteDocument,
    "\n    mutation claimFriendWish($wishId: ID!) {\n        claimWish(wishId: $wishId) {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                wishId\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n        }\n    }\n": types.ClaimFriendWishDocument,
    "\n    mutation createGift($createGift: CreateGiftInput!) {\n        createGift(gift: $createGift) {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n        }\n    }\n": types.CreateGiftDocument,
    "\n    mutation createGroup($groupName: String!) {\n        createGroup(groupName: $groupName) {\n            id\n        }\n    }\n": types.CreateGroupDocument,
    "\n    mutation createGroupInvite($groupId: ID!, $email: String!) {\n        createGroupInvite(groupId: $groupId, email: $email) {\n            id\n            groupId\n            groupName\n            inviterId\n            inviterGivenName\n            inviterSurname\n            inviteeEmail\n        }\n    }\n": types.CreateGroupInviteDocument,
    "\n    mutation createWish($createWish: CreateWishInput!) {\n        createWish(wish: $createWish) {\n            id\n            title\n            description\n            url\n            image\n            isDraft\n            createdAt\n        }\n    }\n": types.CreateWishDocument,
    "\n    mutation deleteGift($giftId: ID!) {\n        deleteGift(giftId: $giftId)\n    }\n": types.DeleteGiftDocument,
    "\n    mutation deleteWish($wishId: ID!) {\n        deleteWish(wishId: $wishId)\n    }\n": types.DeleteWishDocument,
    "\n    mutation leaveGroup($groupId: ID!) {\n        leaveGroup(groupId: $groupId)\n    }\n": types.LeaveGroupDocument,
    "\n    mutation saveEditGift($giftId: ID!, $gift: EditGiftInput!) {\n        editGift(giftId: $giftId, gift: $gift) {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                wishId\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n        }\n    }\n": types.SaveEditGiftDocument,
    "\n    mutation saveEditWish($wishId: ID!, $wish: EditWishInput!) {\n        editWish(wishId: $wishId, wish: $wish) {\n            id\n            wishId\n            title\n            description\n            url\n            image\n            isDraft\n            createdAt\n        }\n    }\n": types.SaveEditWishDocument,
    "\n    query getFriendWishes($userId: ID!) {\n        friend(userId: $userId) {\n            id\n            wishes {\n                id\n                title\n                isClaimed\n                isClaimedBySomeoneElse\n            }\n        }\n    }\n": types.GetFriendWishesDocument,
    "\n    query getGroupDetails ($groupId: ID!) {\n        group(groupId: $groupId) {\n            id\n            groupName\n            otherMembers {\n                id\n                givenName\n                surname\n                wishes {\n                    id\n                    createdAt\n                    modifiedAt\n                    title\n                    description\n                    url\n                    image\n                    isClaimed\n                    isClaimedBySomeoneElse\n                }\n            }\n        }\n        myGifts {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n        }\n    }\n": types.GetGroupDetailsDocument,
    "\n    query getGroupSummary ($groupId: ID!) {\n        group(groupId: $groupId) {\n            id\n            groupName\n        }\n    }\n": types.GetGroupSummaryDocument,
    "\n    query getMyGift($giftId: ID!) {\n        myGift(giftId: $giftId) {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n            recipient {\n                id\n                givenName\n                surname\n            }\n        }\n    }\n": types.GetMyGiftDocument,
    "\n    query getMyGifts {\n        myGifts {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            currentWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n                isClaimed\n                isClaimedBySomeoneElse\n            }\n            originalWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n            recipient {\n                id\n                givenName\n                surname\n            }\n        }\n    }\n": types.GetMyGiftsDocument,
    "\n    query getMyGroups {\n        groups {\n            id\n            groupName\n        }\n    }\n": types.GetMyGroupsDocument,
    "\n    query getMyWish ($wishId: ID!) {\n        getMyWish(wishId: $wishId) {\n            id\n            createdAt\n            modifiedAt\n            title\n            description\n            url\n            image\n            isDraft\n        }\n    }\n": types.GetMyWishDocument,
    "\n    query getMyWishes {\n        myWishes {\n            id\n            createdAt\n            modifiedAt\n            title\n            description\n            url\n            image\n            isDraft\n        }\n    }\n": types.GetMyWishesDocument,
    "\n    query listFriends {\n        friends {\n            id,\n            givenName,\n            surname,\n        }\n    }\n": types.ListFriendsDocument,
    "\n    query lookupGroupInvite($inviteId: ID!) {\n        previewGroupInvite(inviteId: $inviteId) {\n            inviteId\n            groupId\n            groupName\n            inviterId\n            inviterGivenName\n            inviterSurname\n            inviteeEmail\n        }\n    }\n": types.LookupGroupInviteDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation acceptGroupInvite($inviteId: ID!) {\n        acceptGroupInvite(inviteId: $inviteId) {\n            id\n            groupId\n            groupName\n            inviterId\n            inviterGivenName\n            inviterSurname\n            inviteeEmail\n        }\n    }\n"): (typeof documents)["\n    mutation acceptGroupInvite($inviteId: ID!) {\n        acceptGroupInvite(inviteId: $inviteId) {\n            id\n            groupId\n            groupName\n            inviterId\n            inviterGivenName\n            inviterSurname\n            inviteeEmail\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation claimFriendWish($wishId: ID!) {\n        claimWish(wishId: $wishId) {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                wishId\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation claimFriendWish($wishId: ID!) {\n        claimWish(wishId: $wishId) {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                wishId\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation createGift($createGift: CreateGiftInput!) {\n        createGift(gift: $createGift) {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation createGift($createGift: CreateGiftInput!) {\n        createGift(gift: $createGift) {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation createGroup($groupName: String!) {\n        createGroup(groupName: $groupName) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation createGroup($groupName: String!) {\n        createGroup(groupName: $groupName) {\n            id\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation createGroupInvite($groupId: ID!, $email: String!) {\n        createGroupInvite(groupId: $groupId, email: $email) {\n            id\n            groupId\n            groupName\n            inviterId\n            inviterGivenName\n            inviterSurname\n            inviteeEmail\n        }\n    }\n"): (typeof documents)["\n    mutation createGroupInvite($groupId: ID!, $email: String!) {\n        createGroupInvite(groupId: $groupId, email: $email) {\n            id\n            groupId\n            groupName\n            inviterId\n            inviterGivenName\n            inviterSurname\n            inviteeEmail\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation createWish($createWish: CreateWishInput!) {\n        createWish(wish: $createWish) {\n            id\n            title\n            description\n            url\n            image\n            isDraft\n            createdAt\n        }\n    }\n"): (typeof documents)["\n    mutation createWish($createWish: CreateWishInput!) {\n        createWish(wish: $createWish) {\n            id\n            title\n            description\n            url\n            image\n            isDraft\n            createdAt\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation deleteGift($giftId: ID!) {\n        deleteGift(giftId: $giftId)\n    }\n"): (typeof documents)["\n    mutation deleteGift($giftId: ID!) {\n        deleteGift(giftId: $giftId)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation deleteWish($wishId: ID!) {\n        deleteWish(wishId: $wishId)\n    }\n"): (typeof documents)["\n    mutation deleteWish($wishId: ID!) {\n        deleteWish(wishId: $wishId)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation leaveGroup($groupId: ID!) {\n        leaveGroup(groupId: $groupId)\n    }\n"): (typeof documents)["\n    mutation leaveGroup($groupId: ID!) {\n        leaveGroup(groupId: $groupId)\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation saveEditGift($giftId: ID!, $gift: EditGiftInput!) {\n        editGift(giftId: $giftId, gift: $gift) {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                wishId\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation saveEditGift($giftId: ID!, $gift: EditGiftInput!) {\n        editGift(giftId: $giftId, gift: $gift) {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                wishId\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation saveEditWish($wishId: ID!, $wish: EditWishInput!) {\n        editWish(wishId: $wishId, wish: $wish) {\n            id\n            wishId\n            title\n            description\n            url\n            image\n            isDraft\n            createdAt\n        }\n    }\n"): (typeof documents)["\n    mutation saveEditWish($wishId: ID!, $wish: EditWishInput!) {\n        editWish(wishId: $wishId, wish: $wish) {\n            id\n            wishId\n            title\n            description\n            url\n            image\n            isDraft\n            createdAt\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getFriendWishes($userId: ID!) {\n        friend(userId: $userId) {\n            id\n            wishes {\n                id\n                title\n                isClaimed\n                isClaimedBySomeoneElse\n            }\n        }\n    }\n"): (typeof documents)["\n    query getFriendWishes($userId: ID!) {\n        friend(userId: $userId) {\n            id\n            wishes {\n                id\n                title\n                isClaimed\n                isClaimedBySomeoneElse\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getGroupDetails ($groupId: ID!) {\n        group(groupId: $groupId) {\n            id\n            groupName\n            otherMembers {\n                id\n                givenName\n                surname\n                wishes {\n                    id\n                    createdAt\n                    modifiedAt\n                    title\n                    description\n                    url\n                    image\n                    isClaimed\n                    isClaimedBySomeoneElse\n                }\n            }\n        }\n        myGifts {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n        }\n    }\n"): (typeof documents)["\n    query getGroupDetails ($groupId: ID!) {\n        group(groupId: $groupId) {\n            id\n            groupName\n            otherMembers {\n                id\n                givenName\n                surname\n                wishes {\n                    id\n                    createdAt\n                    modifiedAt\n                    title\n                    description\n                    url\n                    image\n                    isClaimed\n                    isClaimedBySomeoneElse\n                }\n            }\n        }\n        myGifts {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getGroupSummary ($groupId: ID!) {\n        group(groupId: $groupId) {\n            id\n            groupName\n        }\n    }\n"): (typeof documents)["\n    query getGroupSummary ($groupId: ID!) {\n        group(groupId: $groupId) {\n            id\n            groupName\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getMyGift($giftId: ID!) {\n        myGift(giftId: $giftId) {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n            recipient {\n                id\n                givenName\n                surname\n            }\n        }\n    }\n"): (typeof documents)["\n    query getMyGift($giftId: ID!) {\n        myGift(giftId: $giftId) {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            originalWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n            recipient {\n                id\n                givenName\n                surname\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getMyGifts {\n        myGifts {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            currentWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n                isClaimed\n                isClaimedBySomeoneElse\n            }\n            originalWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n            recipient {\n                id\n                givenName\n                surname\n            }\n        }\n    }\n"): (typeof documents)["\n    query getMyGifts {\n        myGifts {\n            id\n            wishId\n            recipientId\n            title\n            comments\n            createdAt\n            modifiedAt\n            currentWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n                isClaimed\n                isClaimedBySomeoneElse\n            }\n            originalWish {\n                id\n                createdAt\n                modifiedAt\n                title\n                description\n                url\n                image\n            }\n            recipient {\n                id\n                givenName\n                surname\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getMyGroups {\n        groups {\n            id\n            groupName\n        }\n    }\n"): (typeof documents)["\n    query getMyGroups {\n        groups {\n            id\n            groupName\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getMyWish ($wishId: ID!) {\n        getMyWish(wishId: $wishId) {\n            id\n            createdAt\n            modifiedAt\n            title\n            description\n            url\n            image\n            isDraft\n        }\n    }\n"): (typeof documents)["\n    query getMyWish ($wishId: ID!) {\n        getMyWish(wishId: $wishId) {\n            id\n            createdAt\n            modifiedAt\n            title\n            description\n            url\n            image\n            isDraft\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getMyWishes {\n        myWishes {\n            id\n            createdAt\n            modifiedAt\n            title\n            description\n            url\n            image\n            isDraft\n        }\n    }\n"): (typeof documents)["\n    query getMyWishes {\n        myWishes {\n            id\n            createdAt\n            modifiedAt\n            title\n            description\n            url\n            image\n            isDraft\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query listFriends {\n        friends {\n            id,\n            givenName,\n            surname,\n        }\n    }\n"): (typeof documents)["\n    query listFriends {\n        friends {\n            id,\n            givenName,\n            surname,\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query lookupGroupInvite($inviteId: ID!) {\n        previewGroupInvite(inviteId: $inviteId) {\n            inviteId\n            groupId\n            groupName\n            inviterId\n            inviterGivenName\n            inviterSurname\n            inviteeEmail\n        }\n    }\n"): (typeof documents)["\n    query lookupGroupInvite($inviteId: ID!) {\n        previewGroupInvite(inviteId: $inviteId) {\n            inviteId\n            groupId\n            groupName\n            inviterId\n            inviterGivenName\n            inviterSurname\n            inviteeEmail\n        }\n    }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;
/**
 * Represent a value that can be "ready" or "not ready", and shifts in and out of that state.
 *
 * I'm really using this for session management where:
 * 1. you don't finish logging in until some time after the page loads, and
 * 2. the session token changes periodically as OAuth2 renews the ID token.
 *
 * This really a super special case of Observables where:
 * - You only care about the latest value, and then you stop listening (a Promise is more appropriate than an Observable)
 * - The latest state should be repeated to all subscribers
 *
 * I could probably accomplish this with RXJS observables,
 * but I don't think it would be much clearer or shorter code
 * because it would require returning the current state when subscribed,
 * but I don't think zen-observable (which Apollo Client pulls in) supports that
 * and I don't want to add all of RxJS just for this.
 *
 * This solution wins on the "few dependencies" metric.
 */
export class Readiable<T> {
    static readonly DESTROYED = Symbol('DESTROYED');
    static readonly WAITING = Symbol('WAITING');

    #awaiterQueue: ((user: T | typeof Readiable.DESTROYED) => void)[] = [];
    #currentState: T
        | typeof Readiable.DESTROYED
        | typeof Readiable.WAITING = Readiable.WAITING;

    static create<T>(): Readiable<T> {
        return new Readiable();
    }

    /**
     * Wait for the session state to turn "logged in", then return that user.
     *
     * Returns DESTROYED instead if/when the class is destroyed.
     */
    async nextReady(): Promise<T | typeof Readiable.DESTROYED> {
        if (this.#currentState === Readiable.DESTROYED) {
            return Readiable.DESTROYED;
        }

        if (this.#currentState === Readiable.WAITING) {
            return await new Promise<T | typeof Readiable.DESTROYED>(resolve =>
                this.#awaiterQueue.push(resolve),
            );
        }

        return this.#currentState;
    }

    #resolveAllWaiters(value: T | typeof Readiable.DESTROYED): void {
        // Process array elements one by one, removing them in the process
        for (
            let resolve = this.#awaiterQueue.shift();
            resolve !== undefined;
            resolve = this.#awaiterQueue.shift()
        ) {
            try {
                resolve(value);
            } catch (e) {
                console.error(e);
            }
        }
    }

    setWaiting(): void {
        this.#currentState = Readiable.WAITING;
    }

    setReady(state: T): void {
        this.#currentState = state;
        this.#resolveAllWaiters(state);
    }

    destroy(): void {
        this.#currentState = Readiable.DESTROYED;
        this.#resolveAllWaiters(Readiable.DESTROYED);
    }
}

import { gql } from '../__generated__/index.js';
import type { ResultTypeOfQuery } from '../utils/ResultTypeOfQuery.js';

//language=graphql
export const getGroupSummary = gql(`
    query getGroupSummary ($groupId: ID!) {
        group(groupId: $groupId) {
            id
            groupName
        }
    }
`);

export type GroupSummary = NonNullable<ResultTypeOfQuery<typeof getGroupSummary>['group']>;

import { gql } from '../__generated__/index.js';
import type { ResultTypeOfQuery } from '../utils/ResultTypeOfQuery.js';

//language=graphql
export const getMyGifts = gql(`
    query getMyGifts {
        myGifts {
            id
            wishId
            recipientId
            title
            comments
            createdAt
            modifiedAt
            currentWish {
                id
                createdAt
                modifiedAt
                title
                description
                url
                image
                isClaimed
                isClaimedBySomeoneElse
            }
            originalWish {
                id
                createdAt
                modifiedAt
                title
                description
                url
                image
            }
            recipient {
                id
                givenName
                surname
            }
        }
    }
`);

export type MyGift = ResultTypeOfQuery<typeof getMyGifts>['myGifts'][number];

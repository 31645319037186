import { gql } from '../__generated__/index.js';

//language=graphql
export const acceptGroupInvite = gql(`
    mutation acceptGroupInvite($inviteId: ID!) {
        acceptGroupInvite(inviteId: $inviteId) {
            id
            groupId
            groupName
            inviterId
            inviterGivenName
            inviterSurname
            inviteeEmail
        }
    }
`);
